@import "./fonts.scss";

// Bootstrap
@import "~bootstrap/dist/css/bootstrap.css";

$pink-color: #BE2BBB;
$orange-color: #F26322;
$blue-color: #008FB4;
$gray-color: #595454;
$sm: 575px;
$md: 767px;
$lg: 1024px;
$xl: 1440px;

html, body {
  margin: 0;
  font-size: 14px;
  font-family: "BMS Humanity";
  color: $gray-color;
}

video {
  max-width: 100%;
  cursor: pointer;
}

a:hover {
  text-decoration: none;
}

.container {
  padding: 0;
}

.blurry-background {
  filter: blur(15px);
}

.pink-text {
  color: $pink-color;
  a {
    color: $pink-color;
  }
}

.button-pink {
  background-color: $pink-color;
  color: #ffffff;
  border: 0;
  border-radius: 23px;
  height: 45px;
  width: 194px;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  &:not(:disabled):not(.disabled):focus, &:not(:disabled):not(.disabled):active {
    background-color: $pink-color;
  }

  &:hover {
    color: #ffffff;
    background-color: $pink-color;
  }

  .icon-link-click {
    position: absolute;
    right: 1rem;
  }
}

.button-orange {
  background-color: $orange-color;
  color: #ffffff;
  height: 45px;
  border-radius: 23px;
  width: 170px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &:hover {
    color: #ffffff;
    background-color: $orange-color;
  }
}

.button-blue {
  background-color: $blue-color;
  color: #ffffff;
  height: 45px;
  border-radius: 23px;
  width: 170px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &:hover {
    color: #ffffff;
    background-color: $blue-color;
  }
}

.gray-download-icon-button {
  background-color: #EEE7E7;
  color: $gray-color;
  border: 0;
  border-radius: 23px;
  height: 45px;
  min-width: 464px;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  cursor: pointer;

  &:not(:disabled):not(.disabled):focus, &:not(:disabled):not(.disabled):active {
    background-color: #EEE7E7;
  }

  &:hover {
    color: $gray-color;
    background-color: #EEE7E7;
  }

  img {
    position: absolute;
    right: 1rem;
  }
}

.button-dark {
  background-color: #585454;
  color: #ffffff;
  border: 0;
  border-radius: 23px;
  height: 45px;
  width: 194px;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;

  &:not(:disabled):not(.disabled):focus, &:not(:disabled):not(.disabled):active {
    background-color: #585454;
  }

  &:hover {
    color: #ffffff;
    background-color: #585454;
  }
}

body .arrow-link-pink {
  color: $pink-color;
  font-size: 22px;
  font-weight: 400;
  display: block;
  flex-direction: row;
  align-items: center;

  img {
    margin: 0 0 0 0.5rem;
  }

  &:hover {
    color: $pink-color;
  }
}

.pink-circle {
  background-color: $pink-color;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 1.77vw;
  height: 1.77vw;

  svg {
    font-size: 0.725vw;
    width: 12px;
  }

  &:hover {
    color: #ffffff;
  }
}

header {
  .header-top {
    background-color: #EEE7E7;

    .warning {
      text-align: center;
      padding: 9px 0;
      display: block;
      line-height: 1;

      @media (max-width: $md) {
        font-size: 11px;
        padding: 6px 0;
      }
    }
  }

  .header-bottom {
    .navbar {
      padding: 1rem 25px;

        @media (max-width: $md) {
          padding: 1rem 10px;
        }

      .navbar-brand {
        padding: 0 0 0 2rem;
        display: flex;
        align-items: center;

        .nav-link {
          padding: 0;
        }

        .header-at {
          color: $pink-color;
          font-weight: 300;
          font-size: 24px;
          margin: 0.5rem 0 0 3rem;
        }

        .logo {
          width: 260px;
          height: 75px;
          display: flex;
          flex-direction: column;
          justify-content: center;

          img {
            max-width: 100%;
          }
        }

        @media (max-width: $md) {
          padding: 0;
          min-height: 42px;

          .logo {
            width: 155px;
            height: 39px;
          }

          .header-at {
            font-size: 16px;
            margin: 0.375rem 0 0 1rem;
          }
        }
      }

      @media (max-width: $md) {
        .navbar-toggler {
          border: none;
          padding: 0.25rem 0;

          &:not(.collapsed)> .navbar-toggler-icon {
            background-image: none;
            display: flex;
            align-items: center;
            justify-content: center;

            &:after {
              content: "\2715";
              color: $gray-color;
              font-size: 1.5rem;
            }
          }
        }

        .navbar-collapse {
          .nav-link {
            padding: 1.25rem 0;
            font-size: 16px;
            position: relative;
            line-height: 1;

            &:after {
              content: ">";
              position: absolute;
              right: 0.5rem;
              font-size: 1.25rem;
              font-weight: 600;
            }
          }
        }
      }

      .nav-link {
        color: $gray-color;
        padding: 0.5rem 1rem;
      }
    }
  }
}

.carousel-homepage {
  .carousel-slide {
    max-height: 700px;
    background-position: center center;
    background-size: cover;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: calc(100vw / 2.75);
    color: $gray-color;

    .home-slide-1-welcome {
      font-size: 60px;
      font-weight: 300;
      margin: 0;
      max-width: 470px;
      line-height: 1;
    }

    h2 {
      font-size: 60px;
      font-weight: 300;
      margin: 0 0 2.5rem;
      line-height: 1;
      max-width: 600px;
    }

    .home-slide-2-not-patient {
      position: absolute;
      bottom: 0.5rem;
      right: 5%;
      font-size: 1.05vw;
    }

    .home-slide-3-wrapper {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      text-align: right;
    }

    .home-slide-3-not-patient {
      position: absolute;
      bottom: 0.5rem;
      left: 2%;
      font-size: 1.05vw;
    }

    .home-slide-5-h2 {
      max-width: 460px;
      margin-bottom: 4rem;
    }

    .home-slide-4-not-patient {
      position: absolute;
      bottom: 0.5rem;
      right: 11%;
      font-size: 1.05vw;
    }

    .home-slide-5-not-patient {
      position: absolute;
      bottom: 0.5rem;
      right: 5%;
      font-size: 1.05vw;
    }
  }
}

.carousel-indicators {
  height: 10px;
  margin-bottom: 0;
  bottom: 1rem;

  li {
    width: 64px;
    height: 10px;
    border-radius: 14px;
    opacity: 1;
    background-clip: unset;
    border: 0;
    margin: 0 1rem;
  }

  li.active {
    background: #707070;
  }
}

.homepage-cards-wrapper {
  background-color: #F5F0F0;
  padding: 7.5rem 0 5rem;
  color: $gray-color;

  .container {
    display: flex;
    justify-content: center;

    .homepage-card {
      display: flex;
      flex-direction: column;
      background-color: #ffffff;
      padding: 1.75rem 2.5rem 2.25rem;
      border-top: 10px solid rgb(238, 231, 231);
      box-shadow: 0 2px 2px 0 #f2e7e7, 0 2px 2px 0 #f2e7e7;

      h3 {
        font-size: 30px;
        font-weight: 300;
      }

      > img {
        max-height: 132px;
        max-width: 132px;
        margin: 1.25rem auto 2rem ;
      }

      .homepage-card-3-image {
        margin: 1.25rem auto 2rem;
      }

      .homepage-card-links {
        list-style-type: none;
        padding: 0;
        margin: 0 0 2rem;

        li {
          line-height: 1.6;
        }
      }

      &:not(:last-of-type) {
        margin-right: 2rem;
      }
    }
  }
}

.homepage-cards-middle-wrapper {
  padding: 2.5rem 0;

  .homepage-cards-middle-top {
    background-color: #EEE7E7;
    padding: 4rem;
    display: flex;
    margin-bottom: 2rem;

    .card-left {
      padding-top: 0.5rem;

      h3 {
        font-size: 32px;
        margin-bottom: 1.5rem;
      }

      p {
        font-size: 23px;
        max-width: 630px;
        font-weight: 300;
        line-height: 1.25;
      }
    }

    .card-right {
      padding-left: 3.5%;
      border-left: 2px solid #707070;
      margin-left: 2.5%;
      padding-top: 0.5rem;

      .date {
        font-size: 30px;
        font-weight: 600;
        margin: 0;
        line-height: 1.25;
      }

      .time {
        font-size: 30px;
        font-weight: 300;
        margin-bottom: 1.5rem;
        line-height: 1.25;
      }

      .button-pink {
        width: auto;
        max-width: 260px;
      }
    }
  }

  .homepage-cards-middle-bottom {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    grid-gap: 2rem;

    .homepage-middle-card {
      display: flex;
      flex-direction: column;
      background-color: #ffffff;
      padding: 1.75rem 2.5rem 2.25rem;
      border-top: 10px solid rgb(210, 202, 200);
      box-shadow: 0 0 2px 0 #f2e7e7, 0 0 2px 0 #f2e7e7;

      h3 {
        margin: 0;
        font-weight: 600;
      }

      .homepage-middle-card-line {
        width: 64px;
        border: 3px solid #A69F9F;
        border-radius: 3px;
        margin: 1.5rem 0 3.25rem 0;
        background-color: #A69F9F;
      }
    }
  }
}

.homepage-cards-middle-wrapper {
  padding: 7.5rem 0;

  .container {
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    grid-gap: 2rem;

    .homepage-middle-card {
      display: flex;
      flex-direction: row;
      background-color: #ffffff;
      padding: 1.75rem 2.5rem 2.25rem;
      border-top: 10px solid rgb(210, 202, 200);
      box-shadow: 0 0 2px 0 #f2e7e7, 0 0 2px 0 #f2e7e7;

      h3 {
        margin: 0;
        font-weight: 600;
      }

      .homepage-middle-card-line {
        width: 64px;
        border: 3px solid #A69F9F;
        border-radius: 3px;
        margin: 1.5rem 0 3.25rem 0;
        background-color: #A69F9F;
      }

      .homepage-middle-card-line-second {
        margin: 1.5rem 0 1rem 0;
      }

      .homepage-middle-card-line-second {
        margin: 1.5rem 0 1rem 0;
      }
      .homepage-middle-card-single{
        flex-basis: 50%;
        display:flex;
        flex-direction: column;

        .homepage-middle-card-line {
          width: 64px;
          border: 3px solid #A69F9F;
          border-radius: 3px;
          margin: 1.5rem 0 0.25rem 0;
          background-color: #A69F9F;
        }

      }
    }
  }
}

.homepage-cards-bottom-wrapper {
  padding: 5rem 0;
  background-color: rgb(254, 246, 244);

  .container {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-gap: 2rem;

    .homepage-bottom-card {
      display: flex;
      flex-direction: column;
      background-color: #EEE7E7;
      padding: 3.75rem 2.5rem 4rem;

      h3 {
        font-size: 32px;
        font-weight: 300;
        margin: 0 0 1.25rem;
        color: $gray-color;
      }

      .button-pink {
        height: 35px;
      }
    }
  }
}

.handholding-section {
  max-height: 435px;
  background-position: center center;
  background-size: cover;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: calc(100vw / 4.4);

  h2 {
    text-align: left;
    max-width: 570px;
    font-weight: 300;
    float: right;
    font-size: 52px;
  }

  .trade-icon {
    font-size: 28px;
    font-weight: 300;
    top: -16px;
  }
}

footer {
  background-color: #EEE7E7;
  color: $gray-color;

  .footer-top {
    padding: 2rem 0;

    .footer-top-left {
      p {
        margin: 0;
      }

      .logo {
        width: 260px;
        height: 75px;
        display: flex;
        flex-direction: column;
        justify-content: center;

        img {
          max-width: 100%;
        }
      }
    }

    .footer-top-right {
      float: right;

      p {
        font-size: 16px;
        font-weight: 600;
        margin: 1rem 0;
      }
    }

    .footer-top-links-wrapper {
      ul {
        padding: 0;
        list-style-type: none;
        display: flex;
        justify-content: center;
        margin: 0.5rem 0 1rem;

        li {
          padding: 0 3rem;

          &:not(:last-of-type) {
            border-right: 3px solid $gray-color;
          }

          a {
            color: $gray-color;
            text-decoration: none;
          }
        }
      }
    }
  }
}

.social-icons-wrapper {
  height: 22px;

  a {
    color: $gray-color;

    &:not(:last-of-type) {
      margin-right: 0.75rem;
    }

    svg {
      height: 22px;
      width: 22px;
    }
  }
}

.modal-homepage-wrapper {
  .modal-dialog {
    max-width: 524px;

    .modal-header {
      border-bottom: 0;
      padding: 1rem;
      display: flex;
      justify-content: flex-end;

      button {
        color: $pink-color;
        opacity: 1;
        padding: 0;
        margin: 0;
        height: 14px;
        width: 14px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 2.5rem;
        font-weight: 300;
      }
    }

    .modal-body {
      padding: 6.75rem 5rem 9rem;

      ul.homepage-modal-links {
        padding: 0;
        margin: 0;

        li {
          list-style-type: none;

          &:not(:last-of-type) {
            margin: 0 0 1rem;
          }

          a {
            font-size: 22px;
            color: $pink-color;
            font-weight: 300;
          }
        }

      }
    }
  }
}

.scroll-button {
  position: fixed;
  bottom: 2rem;
  right: 2rem;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #ffffff;
  color: $pink-color;
  height: 48px;
  width: 48px;
  border-radius: 100%;
  font-weight: 300;

  svg {
    font-size: 1.75rem;
    font-weight: 100;
    height: 22px;
    line-height: 1;
    margin-top: 4px;
  }

  span {
    line-height: 1;
  }
}

.marker-lady-wrapper {
  max-height: 384px;
  background-position: center center;
  background-size: cover;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: calc(100vw / 5);

  h1 {
    font-size: 60px;
    font-weight: 300;
    max-width: 400px;
    line-height: 1.1;
  }
}

.abstracts-cards-section {
  scroll-behavior: smooth;
  color: #707070;
  margin-bottom: 6rem;

  h3 {
    font-size: 32px;
    font-weight: 300;
    border-top: 4px solid #EEE7E7;
    padding-top: 1rem;
  }

  h4 {
    color: $pink-color;
    font-size: 22px;
    font-weight: 400;
    padding: 2rem 0;
    margin: 0;
  }

  .abstract-cards-wrapper {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    grid-gap: 2.5rem;

    .abstract-card {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      background-color: #F5F0F0;
      padding: 2.5rem;
      font-family: 'Open Sans', sans-serif;

      .name, .type, .number {
        font-weight: 800;
        margin: 0;
      }

      .description {
        font-size: 20px;
        margin: 0 0 2rem;
        line-height: 1.4;
      }
    }
  }
}

.smiling-guy-wrapper {
  max-height: 405px;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: calc(100vw / 4.75);

  .container {
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    .content-wrapper {
      max-width: 460px;
      padding: 0 170px 0 0;

      h2 {
        font-size: 32px;
        font-weight: 200;
        line-height: 1.1;
        margin: 0 0 1rem;
      }
    }

    .medicines-smiling-guy-content-wrapper {
      max-width: 520px;
      padding: 0 150px 0 0;

      p {
        font-size: 32px;
        font-weight: 300;
        line-height: 1.1;
      }
    }
  }
}

.banner-theater-schedule-wrapper {
  max-height: 384px;
  background-position: center center;
  background-size: cover;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: calc(100vw / 5);

  h1 {
    font-size: 60px;
    font-weight: 300;
    max-width: 400px;
    line-height: 1.1;
  }
}

.product-theater-schedule-cards {
  padding: 5rem 0 7.5rem;
  color: #707070;

  .container {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-gap: 2rem;

    .product-theater-schedule-card {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      background-color: #F5F0F0;
      padding: 3.5rem 2.25rem 2rem;
      min-height: 407px;

      p {
        margin: 0;
      }

      .speaker-name {
        font-weight: 700;
        font-family: "BMS Humanity";
      }

      .event-name {
        font-size: 28px;
        min-height: 9rem;
        font-family: "BMS Humanity";
        line-height: 1.25;
      }

      .event-date-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;

        .date {
          font-size: 18px;
          font-weight: 600;
        }

        .arrow-link-pink {
          justify-content: flex-end;
        }
      }
    }
  }
}

@media (max-width: 767px)  {

  .product-theater-schedule-cards {
    padding: 5rem 0 7.5rem;
    color: #707070;

    .container {
      display: grid;
      grid-template-columns: repeat(1, minmax(0, 1fr));
      grid-gap: 2rem;

      .product-theater-schedule-card {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        background-color: #F5F0F0;
        padding: 3.5rem 2.25rem 2rem;
        min-height: 327px;

        p {
          margin: 0;
        }

        .speaker-name {
          font-size:11px;
          font-weight: 700;
          font-family: "BMS Humanity";
        }

        .event-name {
          font-size: 23px;
          min-height: 9rem;
          font-family: "BMS Humanity";
          line-height: 1.25;
        }

        .event-date-wrapper {
          display: flex;
          justify-content: space-between;
          flex-direction: column;
          align-items: flex-start;
          .date {
            font-size: 12px;
            line-height: 18px;
            font-weight: 600;
            margin-bottom:5px;
          }

          .arrow-link-pink {
            font-size: 16px;
          }
        }
      }
    }
  }
}




.carousel-our-medicines {
  .carousel-slide {
    max-height: 384px;
    background-position: center center;
    background-size: cover;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: calc(100vw / 5);
    color: $gray-color;

    .our-medicines-slide-1-h2 {
      font-size: 60px;
      font-weight: 300;
    }

    .our-medicines-slide-2-content-wrapper {
      display: flex;
      justify-content: space-between;

      .our-medicines-slide-2-p1 {
        font-family: "Klavika";
        color: #004164;
        font-size: 68px;
        line-height: 82px;
        margin-bottom: 0;
        text-transform: uppercase;
      }

      .our-medicines-slide-2-p2 {
        font-family: "Klavika";
        color: #004164;
        font-size: 34px;
        line-height: 41px;
        margin-bottom: 0;
        text-transform: uppercase;
      }

      .button-orange {
        margin-top: 3rem;
      }
    }

    .our-medicines-slide-3-wrapper {
      p {
        font-size: 50px;
        color: #fff;
        font-weight: 300;
        max-width: 620px;
        line-height: 1.2;
        margin-bottom: 2rem;
      }

      .button-orange {
        width: 164px;
      }
    }

    .our-medicines-slide-4-wrapper {
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      h2 {
        font-size: 60px;
        max-width: 460px;
        line-height: 68px;
        margin-bottom: 2rem;
        text-align: right;
        font-weight: 300;
      }
    }

    .our-medicines-slide-4-not-patient {
      position: absolute;
      bottom: 0.5rem;
      left: 2%;
      font-size: 1.05vw;
    }
  }
}

.section-medicines-cards {
  margin: 3rem 0 3rem;
  color: $gray-color;

  .container {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-gap: 2rem;

    .card-fullwidth {
      grid-column: auto / span 2;
      .medicines-inner {
        a {
          padding: 1.75rem 0 3.75rem;
        }
      }
    }

    .medicines-card {
      .medicines-inner {
        border-top: 10px solid #EEE7E7;
        box-shadow: 0 0 6px 1px #f2e7e7;
        display: flex;
        justify-content: center;
        min-height: 220px;

        a {
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          padding: 2rem;

          img {
            max-width: 100%;
          }
        }

        .medicines-inner-abrax {
          width: 77%;
        }
      }

      h3 {
        font-size: 22px;
        font-weight: 600;
        text-transform: uppercase;
        margin: 1rem 0 0;

        sup {
          font-size: 1rem;
        }
      }
    }
  }
}

.please-see-paragraph {
  font-size: 22px;
  margin-bottom: 2rem;
  line-height: 26px;

  .boxed {
    font-weight: 600;
    text-transform: uppercase;
  }

  .abraxane {
    color: #008FB4;
    text-transform: uppercase;
    text-decoration: underline;
  }
}

.our-medicines-bottom-wrapper {
  margin-bottom: 2rem;

  .container {
    background-color: #EEE7E7;
    padding: 3rem 9rem;

    h3 {
      font-size: 32px;
      line-height: 36px;
      font-weight: 300;
    }

    p {
      font-size: 16px;
      line-height: 1.25;
      margin-bottom: 2.5rem;
    }

    .button-pink {
      width: 140px;
      height: 35px;
      font-size: 16px;
    }
  }
}

.banner-oncology-our-research {
  max-height: 500px;
  background-position: center center;
  background-size: cover;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: calc(100vw / 3.76);
  color: $gray-color;

  h1 {
    font-size: 55px;
    font-weight: 300;
    max-width: 720px;
    line-height: 68px;
  }

  .banner-line-pink {
    background-color: $pink-color;
    border-top: 4px solid $pink-color;
    border-bottom: 4px solid $pink-color;
    width: 214px;
    border-radius: 4px;
    margin-bottom: 1.5rem;
  }

  .banner-oncology-p1 {
    font-size: 23px;
    line-height: 32px;
    font-style: italic;
    max-width: 650px;
    margin-bottom: 1.5rem;
  }

  .banner-oncology-bottom-cells {
    display: flex;
    align-items: center;

    .oncology-cell {
      max-width: 216px;
      margin-right: 2rem;
      display: flex;
      justify-content: space-between;
      align-items: center;

      img {
        max-height: 48px;
        margin-right: 0.75rem;
      }

      span {
        font-size: 21px;
        line-height: 24px;
      }
    }
  }
}

.oncology-our-research-immuno-oncology {
  margin: 4rem 0 3rem;

  .immuno-oncology-row {
    display: flex;
    margin-bottom: 3rem;
    justify-content: space-between;

    img {
      max-width: 268px;
      max-height: 43px;
    }

    .quick-links-wrapper {
      display: flex;
      border-bottom: 1px solid $pink-color;
      margin-left: 4rem;
      height: 28px;
      margin-top: 0.25rem;

      span {
        font-size: 23px;
        line-height: 1;
        text-transform: uppercase;
        margin-right: 1rem;
      }

      a {
        line-height: 1;
        height: 22px;
        margin-left: 2.5rem;
      }
    }
  }

  .main-content {
    display: flex;
    justify-content: space-between;

    .content-left {
      h2 {
        font-weight: 100;
        font-size: 2.75rem;
        line-height: 1.25;
      }

      p {
        font-size: 1.5rem;
        font-weight: 300;
        max-width: 680px;
        line-height: 1.25;
        margin-bottom: 1.5rem;
      }

      .immuno-oncology-cards {
        display: flex;
        justify-content: space-between;
        max-width: 680px;
        margin-bottom: 2rem;

        .oncology-card {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-between;

          img {
            max-width: 68px;
            max-height: 68px;
            margin-bottom: 0.5rem;
          }

          span {
            font-size: 1.25rem;
            line-height: 1;
          }
        }
      }
    }

    .immuno-oncology-links {
      a {
        font-size: 17px;
        margin-bottom: 1rem;
        line-height: 1;
      }
    }

    .img-right {
      width: 346px;
      height: 290px;
      margin-left: 4rem;
    }
  }
}

.potential-of-io-combinations {
  .border-top {
    border-top: 3px solid #ede7e7 !important;
    border-bottom: 3px solid #ede7e7 !important;;
    background-color: #ede7e7;
    border-radius: 3px;
    margin-bottom: 4rem;
  }

  .content-wrapper {
    display: flex;
    justify-content: space-between;

    .part-left {
      .part-left-top {
        display: flex;
        justify-content: space-between;

        h2 {
          font-size: 40px;
          line-height: 46px;
          max-width: 380px;
          font-weight: 100;
          margin-right: 0.5rem;
        }

        .img-wrapper {
          width: 344px;
          height: 290px;
          position: relative;
          background-size: contain;
          background-repeat: no-repeat;
          margin-bottom: 1rem;

          .resource-type {
            position: absolute;
            top: 1rem;
            left: 1rem;
            background-color: #fff;
            height: 18px;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0.5rem;
            border-radius: 2px;

            img {
              width: 14px;
              margin-right: 0.25rem;
              filter: invert(51%) sepia(3%) saturate(26%) hue-rotate(355deg) brightness(77%) contrast(96%);
            }

            span {
              text-transform: uppercase;
            }
          }

          .start-wrapper {
            position: absolute;
            top: 73.5%;
            left: 31%;
            width: 38.25%;
            height: 15.75%;
            border: 2px solid transparent;
            border-radius: 0.5rem;
            cursor: pointer;

            &:hover {
              border: 2px solid #fff;
            }
          }
        }
      }

      .part-left-bottom {
        p {
          font-size: 21px;
          line-height: 1.25;

          &:last-of-type {
            margin: 0;
          }
        }

        a {
          font-size: 20px;
        }
      }
    }

    .part-right {
      margin-left: 2rem;
      padding-left: 2rem;
      border-left: 2px solid #000;
      min-width: 370px;

      p {
        font-size: 21px;
        line-height: 28px;
        font-style: italic;
      }

      .part-right-middle-cells {
        .cell {
          display: flex;
          margin-top: 2rem;

          .cell-img-wrapper {
            height: 100px;
            width: 104px;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: left center;
            margin-right: 2rem;
          }

          .cell-right {
            span {
              font-size: 24px;
              line-height: 1;
              text-transform: uppercase;
              font-weight: 600;
              margin-top: 1rem;
              margin-bottom: 1.5rem;
              display: block;
            }

            a {
              font-size: 20px;
              line-height: 22px;
            }
          }
        }
      }
    }
  }

  .content-bottom {
    display: flex;
    justify-content: space-between;
    margin: 1rem 0 1.5rem;

    small {
      max-width: 300px;
      margin-right: 3rem;
      font-size: 10px;
      display: block;
    }
  }
}

.searching-ways-to-restore {
  .border-top {
    border-top: 3px solid #ede7e7 !important;
    border-bottom: 3px solid #ede7e7 !important;
    background-color: #ede7e7;
    border-radius: 3px;
    margin-bottom: 4rem;
  }

  .content-wrapper {
    display: flex;
    justify-content: space-between;
    margin-bottom: 4rem;

    .content-left {
      max-width: 740px;

      h2 {
        font-size: 40px;
        line-height: 46px;
        font-weight: 100;
        margin-bottom: 1rem;
      }

      p {
        font-size: 22px;
        line-height: 1.25;
      }

      ul {
        padding-left: 2rem;

        li {
          font-size: 22px;

          &::marker {
            color: $pink-color;
          }
        }
      }

      a {
        font-size: 20px;
      }
    }

    .img-wrapper {
      width: 360px;
      height: 304px;
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
    }
  }
}

.our-research-resources-section {
  .border-top {
    border-top: 3px solid #ede7e7 !important;
    border-bottom: 3px solid #ede7e7 !important;;
    background-color: #ede7e7;
    border-radius: 3px;
    margin-bottom: 4rem;
  }

  h2 {
    font-size: 32px;
    font-weight: 300;
    margin: 0 0 2rem;
  }

  .related-resources-wrapper {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    grid-gap: 2rem;
    padding: 0 0 1rem;

    .related-resource {
      display: flex;
      flex-direction: column;

      .related-resource-image-wrapper {
        position: relative;
        width: 100%;
        padding-bottom: 56.9%;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;

        .resource-type {
          background-color: #ffffff;
          position: absolute;
          top: 1rem;
          left: 1rem;
          padding: 0.25rem 0.5rem 0.1rem;
          display: flex;
          align-items: center;
          justify-content: center;
          line-height: 1;
          border-radius: 3px;

          svg {
            color: #A69F9F;
            font-size: 0.75rem;
            margin: 0 0.5rem 2px 0;
          }

          span {
            line-height: 1;
            font-size: 12px;
            font-weight: 800;
            text-transform: uppercase;
          }
        }
      }

      .text-content-wrapper {
        padding: 2rem 1.5rem;
        display: flex;
        flex-direction: column;
        flex: 1;

        h4 {
          font-size: 22px;
          line-height: 1;
          margin-bottom: 1rem;
        }

        p {
          font-size: 16px;
          line-height: 1;
          margin-bottom: 1rem;
        }

        a {
          flex: 1;
          align-items: flex-start;
          justify-content: flex-end;

          img {
            margin: 10px 0 0 0.5rem;
          }
        }
      }
    }
  }
}

.now-enrolling-section {
  .container {
    background-color: #ede7e7;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    h2 {
      text-transform: uppercase;
      font-size: 2rem;
    }

    p {
      font-size: 1.25rem;
      line-height: 1.25;
    }

    ul {
      display: flex;
      list-style-type: none;
      padding: 0;
      margin: 0;

      li {
        line-height: 1;

        a {
          color: $pink-color;
          text-decoration: underline;
          padding: 0 0.5rem;
          line-height: 1;
          font-size: 16px;
        }

        &:not(:last-of-type) {
          border-right: 2px solid #000;
        }
      }
    }
  }
}

.references-section {
  p {
    margin: 3rem 0 2rem;
    font-size: 11px;
    line-height: 1.25;
  }
}

.section-border-top {
  border-top: 4px solid #EEE7E7;
  border-bottom: 4px solid #EEE7E7;
  border-radius: 4px;
  background-color: #EEE7E7;
}

.page-not-found-section {
  padding: 5rem 0;
}

.hematology-our-medicines-wrapper {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  width: 100%;
  height: calc(100vw / 1.77777778);

  .hematology-our-medicines-header {
    height: 9%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .logo {
      width: 13.5%;
      height: 5%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      position: absolute;
      left: 2%;

      img {
        max-width: 100%;
      }
    }

    span {
      font-size: 0.9vw;
      font-weight: 400;
    }
  }

  .main-area {
    height: 80%;
    width: 100%;
    position: relative;

    .main-area-wrapper {
      width: 100%;
      height: 100%;
      padding: 0 7%;
      position: absolute;

      .main-area-wrapper-inner {
        width: 100%;
        height: 100%;
        position: relative;

        h1 {
          position: absolute;
          top: 9.5%;
          left: 23.5%;
          font-size: 2.35vw;
          margin: 0;
        }

        .bms-access-support-wrapper {
          position: absolute;
          top: 5%;
          left: 64.75%;
          width: 11%;
          height: 16%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          text-align: center;

          .bms-access-support-wrapper-inner {
            width: 100%;
            height: 100%;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            text-align: center;
            display: flex;
          }

          span {
            color: $pink-color;
            display: block;
            max-width: 3.5vw;
            line-height: 1.25;
            font-size: 0.9vw;
          }

          .pink-circle {
            position: absolute;
            bottom: 0;
            left: 0;
          }
        }

        .contact-us-wrapper {
          position: absolute;
          top: 3.25%;
          left: 82.25%;
          width: 11.25%;
          height: 19%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          text-align: center;

          .contact-us-wrapper-inner {
            width: 100%;
            height: 100%;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            text-align: center;
            display: flex;
          }

          span {
            color: $pink-color;
            display: block;
            line-height: 1.25;
            font-size: 1.1vw;
          }

          .pink-circle {
            position: absolute;
            bottom: 5%;
            left: 0;
          }
        }

        .link-wrapper {
          cursor: pointer;
          position: absolute;

          &:hover {
            border: 5px solid $pink-color;
            color:$pink-color;
          }
        }

        .abecma-wrapper {
          top: 25.25%;
          left: 4%;
          width: 22.25%;
          height: 25%;
        }

        .breyanzi-wrapper {
          top: 25.25%;
          left: 27.25%;
          width: 22.25%;
          height: 25%;
        }

        .reblozyl-wrapper {
          top: 25.25%;
          left: 50.5%;
          width: 22.25%;
          height: 25%;
        }

        .onureg-wrapper {
          top: 25.25%;
          left: 73.75%;
          width: 22.25%;
          height: 25%;
        }

        .revlimid-wrapper {
          top: 52.25%;
          left: 3.75%;
          width: 16.25%;
          height: 18%;
        }

        .pomalyst-wrapper {
          top: 52.25%;
          left: 21.5%;
          width: 16.25%;
          height: 18%;
        }

        .empliciti-wrapper {
          top: 52.25%;
          left: 39.25%;
          width: 16.25%;
          height: 18%;
        }

        .r2-wrapper {
          top: 52.25%;
          left: 56.5%;
          width: 16.25%;
          height: 18%;
        }

        .inrebic-wrapper {
          top: 74%;
          left: 3.75%;
          width: 16.25%;
          height: 18%;
        }

        .sprycel-wrapper {
          top: 74%;
          left: 21.5%;
          width: 16.25%;
          height: 18%;
        }

        .idhifa-wrapper {
          top: 74%;
          left: 39%;
          width: 16.25%;
          height: 18%;
        }
      }
    }
  }

  .hematology-our-medicines-footer {
    height: 11%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;

    p.info {
      font-size: 1.28vw;
      text-align: center;
      margin: 0.18vw 0 0;
      line-height: 1.5;
    }

    p.copyright-info {
      margin: 0.36vw 0 0;
      font-size: 0.9vw;
    }

    .back-to-home {
      position: absolute;
      left: 0.75vw;
      bottom: 0.75vw;
      width: 12.25vw;
      height: 2.15vw;
      font-size: 1vw;

      img {
        margin: 0 0.7vw 0 0;
        max-width: 1.1vw;
      }
    }

    .privacy-policy-btn {
      position: absolute;
      bottom: 0.75vw;
      right: 0.75vw;
      height: 2.15vw;
      font-size: 1vw;
      width: 10vw;
    }
  }
}

.hematology-our-research-wrapper {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  width: 100%;
  height: calc(100vw / 1.77777778);

  .hematology-our-research-header {
    height: 9%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .logo {
      width: 13.5%;
      height: 5%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      position: absolute;
      left: 2%;

      img {
        max-width: 100%;
      }
    }
  }

  .main-area {
    height: 80%;
    width: 100%;
    position: relative;

    .main-area-wrapper {
      width: 100%;
      height: 100%;
      padding: 0 7%;
      position: absolute;

      .main-area-wrapper-inner {
        width: 100%;
        height: 100%;
        position: relative;

        h1 {
          position: absolute;
          top: 7%;
          left: 4%;
          font-size: 2.65vw;
          margin: 0;
          width: 54%;
          height: 11%;
          display: flex;
          align-items: center;
          justify-content: center;
          line-height: 1;
          font-weight: 400;
        }

        .scientific-resources-wrapper {
          position: absolute;
          top: 69.25%;
          left: 35.75%;
          width: 14%;
          height: 27%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;

          span {
            font-size: 1.25vw;
            text-align: center;
            line-height: 1.25;
          }

          .pink-circle {
            position: absolute;
            bottom: -6%;
            right: -4%;
          }
        }

        .medical-information-wrapper {
          position: absolute;
          top: 89.75%;
          left: 64.5%;
          width: 32%;
          height: 7%;
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          justify-content: center;
          padding: 0 5.5% 0 0;

          span {
            font-size: 1.65vw;
            font-weight: 600;
          }

          .pink-circle {
            position: absolute;
            right: 0;
          }
        }

        .link-wrapper {
          cursor: pointer;
          border: 5px solid transparent;
          position: absolute;

          &:hover {
            border: 5px solid $pink-color;
          }

          h2 {
            margin: 0 0 0.25rem;
            position: absolute;
            bottom: 0;
            width: 100%;
            text-align: center;
            color: $gray-color;
          }

          &:hover {
            > h2 {
              color: $pink-color;
            }
          }
        }

        .rectuiting-clinical-trials-wrapper {
          position: absolute;
          top: 23%;
          left: 4.65%;
          width: 22.75%;
          height: 38.5%;

          h2 {
            color: #fff;
            height: 16%;
            margin: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 1.65vw;
            padding-top: 1%;
          }

          .pink-circle {
            position: absolute;
            right: -2%;
            bottom: -4%;
          }
        }

        .investigation-pathways-wrapper {
          position: absolute;
          top: 23%;
          left: 31.75%;
          width: 22.75%;
          height: 38.75%;

          h2 {
            color: #fff;
            height: 16%;
            margin: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 1.65vw;
            padding-top: 1%;
          }

          .pink-circle {
            position: absolute;
            right: -2%;
            bottom: -4%;
          }
        }

        .our-pipeline-wrapper {
          position: absolute;
          top: 16%;
          left: 77%;
          width: 21.25%;
          height: 29.5%;
          display: flex;
          flex-direction: column;
          justify-content: flex-end;

          a {
            color: $gray-color;
            text-decoration: none;
            height: 21%;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 1.25vw;

            svg {
              margin-left: 2%;
              height: 37%;
              margin-bottom: 1.5%;
            }
          }
        }

        // Hematology Research - Sub-Pages

        //Multiple Myeloma
        .targeting-protein-wrapper {
          top: 21.5%;
          left: 4.5%;
          width: 18%;
          height: 35%;

          h2 {
            font-size: 1.1vw !important;
          }

          &:hover {
            color: $pink-color;
          }
        }

        .targeting-protein-img {
          width: 70%;
          margin: 14% 15% 0;
        }

        .cereblon-modulation-in-multiple-myeloma-wrapper {
          top: 21.5%;
          left: 24.5%;
          width: 18%;
          height: 35%;

          h2 {
            font-size: 1.3vw !important;
          }

          &:hover {
            color: $pink-color;
          }
        }

        .cereblon-modulation-in-multiple-myeloma-img {
          width: 100%;
          margin-top: 8%;
        }

        .targeting-BCMA-in-multiple-myeloma-wrapper {
          top: 21.5%;
          left: 45.3%;
          width: 18%;
          height: 35%;

          h2 {
            font-size: 1.2vw !important;
          }

          &:hover {
            color: $pink-color;
          }
        }

        .targeting-BCMA-in-multiple-myeloma-img {
          width: 100%;
          margin-top: 8%;
        }

        .clinical-trial-Idecabtagene-vicleucel-in-multiple-myeloma-wrapper {
          top: 60%;
          left: 4.5%;
          width: 18%;
          height: 36%;

          h2 {
            font-size:1.1vw !important;
          }

          &:hover {
            color: $pink-color;
          }
        }

        .clinical-trial-Idecabtagene-vicleucel-in-multiple-myeloma-img {
          width: 100%;
          margin-top: 8%;
        }

        .multiple-myeloma-additional-clinical-trials-wrapper {
          position: absolute;
          top: 61%;
          left: 35%;
          width: 11.5%;
          height: 24%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;

          span {
            font-size: 1.5rem;
            max-width: 100px;
            text-align: center;
            line-height: 1.25;
          }

          .pink-circle {
            position: absolute;
            top: 0;
            right: 0;
          }
        }

        .multiple-myeloma-scientific-resources-wrapper {
          position: absolute;
          top: 61%;
          left: 51.5%;
          width: 11.5%;
          height: 24%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;

          span {
            font-size: 1.5rem;
            max-width: 100px;
            text-align: center;
            line-height: 1.25;
          }

          .pink-circle {
            position: absolute;
            top: 0;
            right: 0;
          }
        }

        //Myeloid Disease Subpage
        .myelofibrosis-mechanism {
          top: 21.6%;
          left: 4.2%;
          width: 18.2%;
          height: 35%;

          h2 {
            font-size: 1.2vw !important;
            margin-bottom: 0.5rem;
          }

          &:hover {
            color: $pink-color;
          }
        }

        .myelofibrosis-mechanism-img {
          width: 100%;
          margin-top: 8%;
        }

        .fedratinib-wrapper {
          top: 60.2%;
          left: 4.5%;
          width: 18%;
          height: 35.5%;

          h2 {
            font-size: 1.2vw !important;
            margin-bottom: 0.5rem;
          }

          &:hover {
            color: $pink-color;
          }
        }

        .fedratinib-img {
          width: 100%;
          margin-top: 12%;
        }

        .luspatercept-erythropoiesis-wrapper {
          top: 60.2%;
          left: 24.5%;
          width: 18%;
          height: 35.5%;

          h2 {
            font-size: 1.2vw !important;
            margin-bottom: 0.5rem;
          }

          &:hover {
            color: $pink-color;
          }
        }

        .luspatercept-erythropoiesis-img {
          width: 70%;
          margin: 6% 17% 0;
        }

        .luspatercept-mpn-wrapper {
          top: 60.2%;
          left: 45.2%;
          width: 18%;
          height: 35.5%;

          h2 {
            font-size: 1.1vw !important;
            margin-bottom: 0.5rem;
          }

          &:hover {
            color: $pink-color;
          }
        }

        .luspatercept-mpn-img {
          width: 70%;
          margin: 2% 17% 0;
        }

        .additional-clinical-trials-wrapper {
          position: absolute;
          top: 28%;
          left: 32%;
          width: 11.5%;
          height: 24%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;

          span {
            font-size: 1.5rem;
            max-width: 100px;
            text-align: center;
            line-height: 1.25;
          }

          .pink-circle {
            position: absolute;
            top: 0;
            right: 0;
          }
        }

        .myeloid-scientific-resources-wrapper {
          position: absolute;
          top: 28%;
          left: 48.5%;
          width: 11.5%;
          height: 24%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;

          span {
            font-size: 1.5rem;
            max-width: 100px;
            text-align: center;
            line-height: 1.25;
          }

          .pink-circle {
            position: absolute;
            top: 0;
            right: 0;
          }
        }
      }

      .hematology-trials-main-area-wrapper {
        h1 {
          left: 4%;
          top: 7%;
          width: 54%;
          height: 11.5%;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .medical-information-wrapper {
          left: 64.5%;
        }

        h3 {
          position: absolute;
          margin: 0;
          display: flex;
          align-items: center;
          padding-top: 0.5%;
          line-height: 1;
          font-size: 1.25vw;
        }

        .downloadable-panel {
          position: absolute;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          color: $gray-color;
          line-height: 1;
          font-size: 1vw;
          padding-top: 0.25%;

          img {
            height: 28%;
            filter: invert(90%) sepia(13%) saturate(7%) hue-rotate(318deg) brightness(92%) contrast(86%);
            margin-top: 1%;
          }
        }

        .hematology-trials-lymphoma {
          top: 21%;
          left: 4.5%;
          width: 39.75%;
          height: 7%;
          padding-left: 1.5%;
        }

        .hematology-trials-digital-health {
          top: 21.25%;
          left: 45.25%;
          width: 12.75%;
          height: 7%;
          justify-content: center;
        }

        .hematology-trials-myeloid-disease {
          top: 57.25%;
          left: 4.5%;
          width: 39.75%;
          height: 7%;
          padding-left: 1.5%;
        }

        .hematology-trials-multiple-myeloma {
          top: 57.25%;
          left: 45.25%;
          width: 12.75%;
          height: 7%;
          justify-content: center;
        }

        .cc-220-in-dlbcl {
          top: 49%;
          left: 4.5%;
          width: 12.75%;
          height: 6.5%;
        }

        .cc-220-in-nhl {
          top: 49%;
          left: 18%;
          width: 12.75%;
          height: 6.5%;
        }

        .cc-99282-in-nhl-cll {
          top: 49%;
          left: 31.5%;
          width: 12.75%;
          height: 6.5%;
        }

        .my-hope-for-mm-platform {
          top: 49%;
          left: 45.15%;
          width: 12.75%;
          height: 6.5%;
        }

        .fedratinib-in-mf {
          top: 85%;
          left: 4.5%;
          width: 12.75%;
          height: 6.5%;
        }

        .luspatercept-in-mds {
          top: 85%;
          left: 18%;
          width: 12.75%;
          height: 6.75%;
        }

        .luspatercept-in-mf {
          top: 85%;
          left: 31.5%;
          width: 12.75%;
          height: 6.75%;
        }

        .ide-cel-in-mm {
          top: 85%;
          left: 45%;
          width: 12.75%;
          height: 6.75%;
        }

        .hematology-trials-explore-other-trials-wrapper {
          position: absolute;
          top: 16.5%;
          left: 61%;
          width: 14%;
          height: 27%;
          display: flex;
          align-items: center;
          justify-content: center;
          text-align: center;

          .pink-circle {
            position: absolute;
            bottom: -3%;
            right: -3%;
          }

          span {
            font-size: 1.25vw;

            svg {
              font-size: 0.9vw;
            }
          }
        }

        .hematology-trials-our-pipeline-wrapper {
          position: absolute;
          top: 16.5%;
          left: 76.75%;
          width: 21.25%;
          height: 29%;
          display: flex;
          align-items: flex-end;
          justify-content: center;
          text-align: center;

          a {
            font-size: 1.25vw;
            height: 21%;
            color: $gray-color;
            display: flex;
            align-items: center;
            width: 100%;
            justify-content: center;

            svg {
              font-size: 0.9vw;
              margin: 0 0 2% 2%;
            }
          }
        }

        .hematology-trials-investigation-pathways-wrapper {
          position: absolute;
          top: 51.5%;
          left: 61%;
          width: 19%;
          height: 32.5%;
          display: flex;
          align-items: flex-start;
          justify-content: center;
          text-align: center;

          .pink-circle {
            position: absolute;
            bottom: -3%;
            right: -3%;
          }

          span {
            font-size: 1.25vw;
            color: #fff;
            height: 17%;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            padding-top: 2%;
          }
        }
      }

      .investigation-pathways-main-area-wrapper {
        h1 {
          left: 4%;
          top: 7%;
          width: 54%;
          height: 11.5%;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .medical-information-wrapper {
          left: 64.5%;
        }

        .link-wrapper {
          cursor: pointer;
          position: absolute;
          display: flex;
          align-items: flex-end;
          border: 0.375vw solid transparent;

          &:hover {
            border: 0.375vw solid $pink-color;
          }

          h3 {
            margin: 0;
            width: 100%;
            height: 21%;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 1.25vw;
            padding-top: 1%;
          }
        }

        .targeting-bcma-in-mm {
          top: 23.5%;
          left: 6.75%;
          width: 21.75%;
          height: 29.5%;
        }

        .cereblon-modulation {
          top: 23.5%;
          left: 30.75%;
          width: 21.75%;
          height: 29.5%;
        }

        .lymphoma-targets {
          top: 55.5%;
          left: 30.75%;
          width: 21.75%;
          height: 30%;
        }

        .investigation-pathways-explore-other-trials-wrapper {
          position: absolute;
          top: 16.5%;
          left: 61%;
          width: 14%;
          height: 27%;
          display: flex;
          align-items: center;
          justify-content: center;
          text-align: center;

          .pink-circle {
            position: absolute;
            bottom: -3%;
            right: -3%;
          }

          span {
            font-size: 1.25vw;

            svg {
              font-size: 0.9vw;
            }
          }
        }

        .investigation-pathways-our-pipeline-wrapper {
          position: absolute;
          top: 16.5%;
          left: 76.75%;
          width: 21.25%;
          height: 29%;
          display: flex;
          align-items: flex-end;
          justify-content: center;
          text-align: center;

          a {
            font-size: 1.25vw;
            height: 21%;
            color: $gray-color;
            display: flex;
            align-items: center;
            width: 100%;
            justify-content: center;

            svg {
              font-size: 0.9vw;
              margin: 0 0 2% 2%;
            }
          }
        }

        .investigation-pathways-investigation-pathways-wrapper {
          position: absolute;
          top: 51.5%;
          left: 61%;
          width: 19%;
          height: 32.5%;
          display: flex;
          align-items: flex-start;
          justify-content: center;
          text-align: center;

          .pink-circle {
            position: absolute;
            bottom: -3%;
            right: -3%;
          }

          span {
            font-size: 1.25vw;
            color: #fff;
            height: 17%;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            padding-top: 2%;
          }
        }
      }
    }
  }

  .hematology-our-research-footer {
    height: 11%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;

    p.copyright-info {
      margin: 0;
      font-size: 0.9vw;
    }

    .back-to-home {
      position: absolute;
      left: 0.75vw;
      bottom: 0.75vw;
      width: 12.25vw;
      height: 2.15vw;
      font-size: 1vw;

      img {
        margin: 0 0.7vw 0 0;
        max-width: 1.1vw;
      }
    }

    .privacy-policy-btn {
      position: absolute;
      bottom: 0.75vw;
      right: 0.75vw;
      height: 2.15vw;
      font-size: 1vw;
      width: 10vw;
    }
  }
}

.hematology-our-educational-resources-wrapper {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  width: 100%;
  height: calc(100vw / 1.77777778);

  .hematology-our-educational-resources-header {
    height: 9%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .logo {
      width: 13.5%;
      height: 5%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      position: absolute;
      left: 2%;

      img {
        max-width: 100%;
      }
    }
  }

  .main-area {
    height: 80%;
    width: 100%;
    position: relative;

    .main-area-wrapper {
      width: 100%;
      height: 100%;
      padding: 0 7%;
      position: absolute;

      .main-area-wrapper-inner {
        width: 100%;
        height: 100%;
        position: relative;

        h1 {
          position: absolute;
          top: 7%;
          left: 4.5%;
          width: 58.5%;
          height: 11%;
          font-size: 2.375vw;
          margin: 0;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .clinical-nurse-connections-wrapper {
          position: absolute;
          top: 38%;
          left: 7.75%;
          width: 13%;
          height: 56%;
          display: flex;
          flex-direction: column;
          align-items: flex-start;

          .coming-soon {
            color: $pink-color;
          }

          h2 {
            font-size: 1.825vw;
            line-height: 1.25;
            margin: 60% 0 0.35vw;
          }

          p {
            margin: 0;
            font-size: 0.9vw;
          }
        }

        .contact-us-wrapper {
          position: absolute;
          top: 24%;
          left: 42.25%;
          width: 11.25%;
          height: 19%;
          display: flex;
          justify-content: center;
          align-items: center;
          text-align: center;

          .contact-us-wrapper-inner {
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            width: 100%;
            height: 100%;
          }

          span {
            color: $pink-color;
            display: block;
            line-height: 1.25;
            font-size: 1.1vw;
          }

          .pink-circle {
            position: absolute;
            top: 5%;
            right: 10%;
          }
        }

        .link-wrapper {
          position: absolute;
          display: flex;
          align-items: center;
          justify-content: center;
          text-align: center;
          padding: 0.725vw 2.175vw 0;

          .link-wrapper-inner {
            h2 {
              color: $gray-color;
            }
          }

          h2 {
            font-size: 1.275vw;
            margin: 0;
          }

          .pink-circle {
            position: absolute;
            top: 0;
            left: 0;
          }
        }

        .multiple-myeloma-wrapper {
          top: 11.5%;
          left: 70%;
          width: 23%;
          height: 10.5%;
        }

        .myelodysplastic-syndromes-wrapper {
          top: 24.5%;
          left: 70%;
          width: 23%;
          height: 11.5%;
        }

        .acute-myeloid-leukemia-wrapper {
          top: 38.5%;
          left: 70%;
          width: 23%;
          height: 11%;
        }

        .car-t-wrapper {
          top: 51.5%;
          left: 70%;
          width: 23%;
          height: 10.5%;
        }

        .lymphoma-wrapper {
          top: 65.5%;
          left: 70%;
          width: 23%;
          height: 10.5%;
        }
      }
    }
  }

  .hematology-our-educational-resources-footer {
    height: 11%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;

    p.copyright-info {
      margin: 0;
      font-size: 0.9vw;
    }

    .back-to-home {
      position: absolute;
      left: 0.75vw;
      bottom: 0.75vw;
      width: 12.25vw;
      height: 2.15vw;
      font-size: 1vw;

      img {
        margin: 0 0.7vw 0 0;
        max-width: 1.1vw;
      }
    }

    .privacy-policy-btn {
      position: absolute;
      bottom: 0.75vw;
      right: 0.75vw;
      height: 2.15vw;
      font-size: 1vw;
      width: 10vw;
    }
  }
}

.hematology-educational-resources-modal-wrapper {
  .modal-dialog {
    max-width: max-content;

    .modal-content {
      border-radius: 0.75rem;

      .modal-header {
        border-bottom: 0;
        padding: 1.5rem;
        display: flex;
        justify-content: flex-end;

        button {
          color: $pink-color;
          opacity: 1;
          padding: 0;
          margin: 0;
          height: 14px;
          width: 14px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 2.5rem;
          font-weight: 300;
        }
      }

      .modal-body {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 4rem 8rem;

        h2.hematology-section-name {
          font-size: 48px;
          margin: 0 0 1rem;
        }

        hr {
          border-top: 2px solid $pink-color;
          border-bottom: 2px solid $pink-color;
          width: 106px;
          border-radius: 4px;
        }

        ul.hematology-modal-links {
          padding: 0;
          margin: 2rem 0 0;

          li {
            list-style-type: none;

            &:not(:last-of-type) {
              margin: 0 0 1rem;
            }

            a {
              font-size: 22px;
              color: #ffffff;
              font-weight: 700;
              background: #A59F9F;
              border-radius: 20px;
              height: 46px;
              width: 464px;
              display: flex;
              align-items: center;
              justify-content: center;
              position: relative;
              border: none;
              line-height: 1;

              img {
                position: absolute;
                right: 1.25rem;
              }
            }
          }
        }
      }
    }
  }
}

.hematology-medicines-modal-wrapper {
  .modal-dialog {
    max-width: 80vw;
    width: max-content;
    min-width: 500px;

    .modal-content {
      border-radius: 0.75rem;

      .modal-header {
        border-bottom: 0;
        padding: 1.5rem 1.5rem 0;
        display: flex;
        justify-content: flex-end;

        button {
          color: $pink-color;
          opacity: 1;
          padding: 0;
          margin: 0;
          height: 14px;
          width: 14px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 2.5rem;
          font-weight: 300;
        }
      }

      .modal-body {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 1rem 3rem;

        img {
          max-width: 100%;
        }

        .general-section-wrapper {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;

          .button-pink {
            margin: 1.5rem 0 0.5rem;
          }
        }

        .detailed-section-wrapper {
          max-width: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          text-align: center;
          padding: 1.5rem 8rem 4rem;

          hr {
            border-top: 2px solid $pink-color;
            border-bottom: 2px solid $pink-color;
            background-color: $pink-color;
            width: 106px;
            border-radius: 4px;
            margin: 2rem 0;
          }

          p {
            font-size: 18px;

            a {
              color: $gray-color;
              text-decoration: underline;
            }
          }

          .details-link {
            min-width: 600px;
            margin-bottom: 1rem;
          }

          p.click-a-button-below {
            margin: 3rem 0 0.5rem 0;
            font-weight: 700;
          }

          .p-please-see {
            max-width: 500px;
            margin-bottom: 3rem;
          }
        }
      }
    }
  }
}

.hematology-our-research-modal-wrapper {
  .modal-dialog {
    max-width: 70vw;

    .modal-content {
      border-radius: 0.75rem;

      .modal-header {
        border-bottom: 0;
        padding: 1.5rem;
        display: flex;
        justify-content: flex-end;

        button {
          color: $pink-color;
          opacity: 1;
          padding: 0;
          margin: 0;
          height: 14px;
          width: 14px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 2.5rem;
          font-weight: 300;
        }
      }

      .modal-body {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 4rem 8rem;

        h2.hematology-section-name {
          max-width: 430px;
          font-size: 48px;
          margin: 0 auto 1rem;
          text-align: center;
          line-height: 1;
        }

        hr {
          border-top: 2px solid $pink-color;
          border-bottom: 2px solid $pink-color;
          width: 106px;
          border-radius: 4px;
        }

        ul.hematology-modal-links {
          padding: 0;
          margin: 2rem 0 0;

          li {
            list-style-type: none;

            &:not(:last-of-type) {
              margin: 0 0 1rem;
            }

            a {

              img {
                position: absolute;
                right: 1.25rem;
              }
            }
          }
        }
      }
    }
  }
}

.health-equity-modal-wrapper {
  .modal-dialog {
    max-width: 70vw;

    .modal-content {
      border-radius: 0.75rem;

      .modal-header {
        border-bottom: 0;
        padding: 1.5rem;
        display: flex;
        justify-content: flex-end;

        button {
          color: $pink-color;
          opacity: 1;
          padding: 0;
          margin: 0;
          height: 14px;
          width: 14px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 2.5rem;
          font-weight: 300;
        }
      }

      .modal-body {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 4rem 8rem;
      }
    }
  }
}

.video-section-wrapper {
  max-width: 80vw;

  a.video-link-bottom {
    font-size: 22px;
    font-weight: 700;
    display: flex;
    justify-content: center;
    color: $gray-color;
    margin: 2rem 0 1.5rem;
    text-decoration: underline;
    line-height: 1;
  }

  p {
    text-align: center;
    font-size: 18px;

    a {
      color: $gray-color;
      text-decoration: underline;
    }
  }
}

.vimeo-wrapper {
  .embed-vimeo-content {
    width: 80vw;
    height: calc(80vw / 1.7777778);

    max-width: 100%;
    border: none;
  }
}

.coming-soon-section-wrapper {
  background-color: #feeccc;

  .coming-soon-left {
    padding-top: 170px;
    padding-bottom: 120px;

    .welcome-paragraph {
      text-transform: uppercase;
      font-size: 1.25rem;
    }

    .at-asco-2021-paragraph {
      font-size: 5rem;
      font-weight: 300;
      line-height: 1;
      margin-bottom: 1rem;
    }

    .soon-paragraph {
      font-size: 1.25rem;
      max-width: 400px;
      margin-bottom: 2.5rem;
    }

    .calendar-wrapper {
      background-color: #fff;
      padding: 2rem;
      max-width: 340px;
      border-radius: 0.5rem;

      p {
        margin-bottom: 0.5rem;
        display: inline-block;
      }

      .button-pink {
        border-radius: 3px;
        margin: 0.5rem 0;
        font-weight: 300;
        line-height: 1;
        font-size: 1rem;
        width: 144px;
        height: 38px;

        svg {
          margin-right: 0.5rem;
        }

        span {
          margin-top: 0.25rem;
        }
      }
    }
  }

  .hands-image-wrapper {
    img {
      width: 100%;
    }

    p {
      font-size: 2.25rem;
      max-width: 370px;
      line-height: 1.25;
      margin: 0 auto;
    }
  }

  @media (max-width: 767px) {
    .row {
      flex-direction: column-reverse;

      .hands-image-wrapper {
        background-size: contain;
        background-position: center top;

        p {
          font-size: 1.5rem;
          max-width: 300px;
          margin: 0 auto;
          padding-left: 3rem;
        }
      }

      .coming-soon-left {
        padding-top: 60px;
        padding-bottom: 70px;
        max-width: 330px;
        margin: 0 auto;

        .at-asco-2021-paragraph {
          font-size: 3.25rem;
        }

        .soon-paragraph {
          font-size: 1.15rem;
          margin-bottom: 2rem;
        }

        .button-pink {
          margin-bottom: 0;
        }
      }
    }
  }
}

.flex-row-end {
  display: flex;
  justify-content: flex-end;
}

.abstracts-links-wrapper {
  .container {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-gap: 2rem;
    padding-top: 3rem;
    padding-bottom: 5rem;

    .abstracts-links-card {
      background-color: #EEE7E7;
      padding: 1.5rem 2rem 2.5rem;

      h2 {
        font-size: 32px;
        font-weight: 300;
        margin-bottom: 1rem;
      }

      .abstracts-links-wrapper-inner {
        list-style-type: none;
        padding: 0;
        margin: 0;

        .arrow-link-pink {
          cursor: pointer;
          display: inline-block;
        }
      }
    }
  }
}

.banner-health-equity-wrapper {
  max-height: 384px;
  background-position: center center;
  background-size: cover;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: calc(100vw / 5);

  .quote {
    max-width: 580px;
    font-size: 28px;
    font-weight: 300;
    line-height: 1.25;
    margin-bottom: 3rem;
  }

  .author {
    max-width: 650px;
    font-size: 16px;
    font-weight: 300;
    font-style: italic;
    margin: 0;
    line-height: 1.25;
  }
}

.health-equity-main-section {
  padding: 3rem 0 6rem;

  .health-equity-top {
    border-top: 8px solid #EEE7E7;
    box-shadow: 0 -1px 4px 0 #f2e7e7;
    padding: 2rem 5rem 4rem;

    h2 {
      font-size: 31px;
      font-weight: 800;
      line-height: 1.25;
      margin-bottom: 1rem;
    }

    .join-us {
      font-size: 20px;
      max-width: 570px;
      line-height: 1.25;
    }

    .watch-live {
      font-size: 22px;
      font-weight: 800;
      line-height: 1.25;
    }

    .watch-live-state-2 {
      margin-bottom: 1rem;
      width: 140px;
      height: 35px;
    }

    .watch-live-state-3 {
      font-size: 22px;
    }

    .watch-live-state-4 {
      margin-bottom: 1rem;
      width: 225px;
      height: 35px;
    }

    .partisipant-biographies {
      font-size: 20px;
      font-weight: 800;
      border-top: 2px solid #DAC5C5;
      display: inline-block;
      padding-top: 1rem;
      margin-bottom: 8rem;
    }

    .partisipant-cards-wrapper {
      display: grid;
      grid-template-columns: repeat(3, minmax(0, 1fr));
      grid-gap: 2rem;

      .partisipant-card {
        background-color: #F5F0F0;
        padding: 7rem 3rem 1.5rem;
        position: relative;

        .partisipant-name {
          font-size: 18px;
          font-weight: 800;
          font-family: Open Sans;
        }

        img {
          position: absolute;
          top: -70px;
          width: 140px;
          height: 140px;
          left: 50%;
          transform: translate(-50%, 0);
        }

        p {
          font-size: 18px;
          color:#707070;
          font-family: Open Sans;
        }
      }
    }
  }

  .health-equity-bottom {
    margin-top: 4rem;
    border-top: 4px solid #EEE7E7;

    .survivorship-today-wrapper {
      padding: 3rem 0 4rem;
      display: flex;

      .survivorship-today-left {
        display: flex;
        flex-direction: column;
        align-items: center;

        .survivorship-today-logo {
          width: 331px;
          margin-bottom: 1rem;

          img {
            max-width: 100%;
          }
        }

        .button-pink {
          width: 140px;
          height: 35px;
        }
      }

      .survivorship-today-right {
        margin-top: 2rem;
        margin-left: 3rem;
        border-left: 1.5px solid #707070;
        padding-left: 6rem;

        p {
          font-size: 20px;
          line-height: 28px;
          max-width: 750px;
          margin: 0;
        }
      }
    }

    .survivorship-cards {
      display: grid;
      grid-template-columns: repeat(3, minmax(0, 1fr));
      grid-gap: 2rem;

      .survivorship-card-single {
        display: flex;
        flex-direction: column;

        .survivorship-card-image-wrapper {
          padding-bottom: 57.5%;
          background-repeat: no-repeat;
          background-position: center;
          position: relative;

          .survivorship-card-type {
            position: absolute;
            top: 1.5rem;
            left: 1.25rem;
            background-color: #fff;
            width: 69px;
            height: 18px;
            text-align: center;
            border-radius: 3px;
            display: flex;
            justify-content: center;
            align-items: center;

            svg {
              color: $pink-color;
            }

            span {
              text-transform: uppercase;
              margin-left: 0.25rem;
              font-size: 12px;
              line-height: 1;
              margin-top: 2px;
              font-weight: 600;
            }
          }
        }

        .text-content-wrapper {
          padding: 1.5rem;
          box-shadow: 0 4px 4px 0 #f2e7e7;
          display: flex;
          flex-direction: column;

          h3 {
            font-size: 22px;
            line-height: 25px;
            font-weight: 800;
          }

          .watch-link-wrapper {
            display: flex;
            justify-content: flex-end;
            margin-top: 4rem;
            padding-right: 1rem;

            span {
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}



.opdivo-yervoy-banner-wrapper {
  width: 100%;
  height: calc(100vw / 4.683);
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
  position: relative;

  .back-link {
    color: #222F68;
    font-size: 16px;
    text-transform: uppercase;
    position: absolute;
    left: 3rem;
    top: 10%;
    z-index: 1000;
  }

  .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;

    p {
      color: #004164;
      font-family: Klavika;
      line-height: 1;
      text-transform: uppercase;
      margin: 0;
    }

    .opdivo-banner-p1 {
      font-size: 91px;
    }

    .opdivo-banner-p2 {
      font-size: 45px;
    }

    .button-orange {
      width: 370px;
      height: 42px;
      font-size: 20px;
      flex-direction: row;
      margin-top: 3rem;

      img {
        margin-left: 1rem;
      }
    }
  }
}

.opdivo-cabometyx-banner-wrapper {
  width: 100%;
  height: calc(100vw / 4.683);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  overflow: hidden;
  position: relative;

  .back-link {
    color: #fff;
    font-size: 16px;
    text-transform: uppercase;
    position: absolute;
    left: 3rem;
    top: 10%;
    z-index: 1000;
  }

  .container {
    height: 100%;
    position: relative;

    .button-blue {
      width: 395px;
      height: 42px;
      font-size: 20px;
      flex-direction: row;
      position: absolute;
      bottom: 16%;
      padding-top: 4px;

      img {
        margin-left: 1rem;
      }
    }
  }
}

.opdivo-folfox-banner-wrapper {
  width: 100%;
  height: calc(100vw / 4.683);
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
  position: relative;

  .back-link {
    color: #fff;
    font-size: 16px;
    text-transform: uppercase;
    position: absolute;
    left: 3rem;
    top: 10%;
    z-index: 1000;
  }

  .container {
    height: 100%;
    position: relative;

    .button-orange {
      width: 464px;
      height: 42px;
      font-size: 20px;
      flex-direction: row;
      position: absolute;
      left: 1%;
      bottom: 16%;
      padding-top: 4px;

      img {
        margin-left: 1rem;
      }
    }
  }
}

.opdivo-top {
  margin-top: 1.5rem;
  font-family: Arial;

  .product-information-wrapper {
    padding-right: 0.75rem;

    .product-information-wrapper-inner {
      background: #F1F3F2;
      padding: 3rem 2rem;

      h2 {
        font-size: 22px;
        font-weight: 600;
        text-transform: uppercase;
        border-bottom: 2px solid #F26322;
        padding-bottom: 0.5rem;
        margin-bottom: 1.5rem;
      }

      .h2-cabometyx {
        border-bottom: 2px solid $pink-color;
      }

      ul {
        list-style-type: none;
        padding: 0;
        margin: 0;

        li {
          font-size: 14px;
          line-height: 20px;

          &:not(:last-of-type) {
            margin-bottom: 0.5rem;
          }
        }
      }
    }
  }

  .indications {
    padding-left: 0.75rem;

    h2 {
      font-size: 32px;
      font-weight: 600;
      margin-bottom: 2rem;
    }

    .indication-box {
      margin-top: 0.5rem;
      border: 1px solid #BDC0CC;
      display: flex;
      padding: 1rem;
      align-items: center;
      position: relative;
      height: 126px;

      img {
        margin-right: 1rem;
        width: 90px;
      }

      p {
        font-size: 22px;
        line-height: 30px;
        margin: 0;
      }

      .learn-more {
        position: absolute;
        bottom: 0.25rem;
        right: 2.5rem;
        font-size: 17px;
        font-weight: 600;
        text-transform: uppercase;
        color: $gray-color;

        svg {
          color: #F26322;
          margin-left: 0.5rem;
        }
      }
    }
  }
}

.isi-popup-content {
  font-family: Arial;
  position: fixed;
  max-height: calc(100vh - 106px);
  top: 106px;
  left: 0;
  width: 100%;
  background-color: #fff;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.1);
  transition: all 0.5s;
  overflow-y: auto;
  z-index: 1001;

  &.isi-popup-toggled {
    top: 80vh;
    max-height: 20vh;
    overflow-y: hidden;

    .container {
      .isi-popup-toggle-btn {
        &:after {
          content: "+" !important;
          padding-top: 5px;
        }
      }
    }
  }

  &.isi-popup-sticky {
    position: inherit;
    max-height: unset !important;
  }

  .container {
    margin: 2rem auto;
    position: relative;

    .isi-popup-toggle-btn {
      position: absolute;
      top: 0;
      right: 0;
      background-color: #008FB4;
      color: #fff;
      width: 16px;
      height: 16px;
      border-radius: 50%;
      cursor: pointer;

      &:after {
        content: "\2013";
        width: 16px;
        height: 14px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 1px 0;
      }
    }

    h2 {
      color: #003B5C;
      font-size: 16px;
      line-height: 18px;
      font-weight: 600;
      text-transform: uppercase;
      margin-bottom: 1rem;
    }

    h3 {
      color: #003B5C;
      font-size: 16px;
      line-height: 18px;
      font-weight: 600;
      margin-bottom: 0.5rem;
    }

    h4 {
      color: #003B5C;
      font-size: 16px;
      line-height: 18px;
      margin-bottom: 0.5rem;
      text-decoration: underline;
    }

    p {
      font-size: 16px;
      line-height: 20px;
      margin-bottom: 1rem;
    }

    .isi-popup-top-wrapper {
      display: flex;
      justify-content: space-between;
      padding-top: 1rem;

      .important-safety-information {
        max-width: 583px;
        padding-right: 1rem;
      }

      .indications-wrapper {
        border-left: 1px solid #003B5C;
        padding: 0 0 0 3rem;
        margin-bottom: 1rem;
        max-width: 558px;
      }
    }

    a {
      font-size: 16px;
      color: #008FB4;
      text-decoration: underline;
    }
  }
}

.opdivo-explore-section {
  font-family: Arial;
  margin-top: 2rem;

  .container {
    .divider-top {
      border-top: 4px solid #F26322;
      border-bottom: 4px solid #F26322;
      background-color: #F26322;
      border-radius: 4px;
    }

    h2 {
      font-size: 32px;
      font-weight: 600;
      margin: 2rem 0;
    }

    .explore-boxes-wrapper {
      display: grid;
      grid-template-columns: repeat(2, minmax(0, 1fr));
      grid-gap: 1.5rem;
      margin-bottom: 2rem;

      .explore-box {
        box-shadow: 0 2px 2px 0 #f2e7e7, 0 2px 2px 0 #f2e7e7;

        .explore-box-cover-photo {
          padding-bottom: 36.4%;
          background-repeat: no-repeat;
          background-size: cover;
          background-position: center;
          position: relative;

          .explore-box-type {
            position: absolute;
            top: 1rem;
            left: 1rem;
            background-color: #fff;
            text-transform: uppercase;
            padding: 0 0.25rem;
            width: 110px;
            height: 18px;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
              filter: invert(52%) sepia(76%) saturate(7085%) hue-rotate(172deg) brightness(100%) contrast(101%);
              height: 13px;
              width: 15px;
              margin-right: 0.25rem;
            }

            span {
              font-size: 12px;
              line-height: 14px;
              font-weight: 600;
            }
          }
        }

        .explore-box-info {
          padding: 1rem;

          h3 {
            font-size: 22px;
            font-weight: 600;
            line-height: 25px;
          }

          .link-wrapper {
            display: flex;
            justify-content: flex-end;
            margin: 2rem 1rem 2rem 0;

            a {
              color: #F26322;
              text-decoration: none;
              font-size: 22px;
            }
          }
        }
      }
    }
  }
}

.opdivo-product-resources-section {
  margin-bottom: 4rem;

  .opdivo-folfox-border-top {
    background: #F26322;
    border-top: 4px solid #F26322;
    border-bottom: 4px solid #F26322;
    border-radius: 4px;
    margin-top: 1.5rem;
  }

  .opdivo-cabometyx-border-top {
    background: #008FB4;
    border-top: 4px solid #008FB4;
    border-bottom: 4px solid #008FB4;
    border-radius: 4px;
    margin-top: 1.5rem;
  }

  h2 {
    font-size: 32px;
    font-weight: 600;
    margin: 2rem 0;
  }

  .product-resource-boxes-wrapper {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    grid-gap: 6rem 2rem;
    margin-bottom: 2rem;

    .product-resource-box {
      box-shadow: 0 2px 2px 0 #f2e7e7, 0 2px 2px 0 #f2e7e7;
      display: flex;
      flex-direction: column;

      .product-resource-box-cover-photo {
        padding-bottom: 56.8%;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        position: relative;

        .product-resource-box-type {
          position: absolute;
          top: 1rem;
          left: 1rem;
          background-color: #fff;
          text-transform: uppercase;
          padding: 0 0.25rem;
          width: 56px;
          height: 18px;
          display: flex;
          align-items: center;
          justify-content: center;

          img {
            filter: invert(52%) sepia(76%) saturate(7085%) hue-rotate(172deg) brightness(100%) contrast(101%);
            height: 13px;
            width: 15px;
            margin-right: 0.25rem;
          }

          span {
            font-size: 12px;
            line-height: 14px;
            font-weight: 600;
          }
        }
      }

      .product-resource-box-info {
        padding: 1rem;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex: 1 1 auto;

        h3 {
          font-size: 22px;
          font-weight: 600;
          line-height: 25px;
          margin: 0;
        }

        .link-wrapper {
          display: flex;
          justify-content: flex-end;
          margin: 1.5rem 1rem 0.5rem 0;

          a {
            color: #F26322;
            font-size: 22px;
            text-decoration: none;
            display: flex;
            align-items: center;

            img {
              margin-left: 0.5rem
            }
          }
        }
      }
    }
  }
}

.opdivo-banner-section-bottom {
  background-color: #E8E9EB;
  font-family: Arial;
  padding-bottom: 20vh;

  &.no-padding-bottom {
    padding-bottom: 0;
  }

  .container {
    display: flex;
    align-items: center;
    padding: 1rem 0;
    height: calc(100vw / 4.74);

    img {
      max-width: 100%;
    }

    .paragraph-wrapper {
      display: flex;
      justify-content: flex-end;
      margin-top: 1rem;

      p {
        font-size: 32px;
        line-height: 36px;
        max-width: 420px;
        margin: 0;
      }
    }
  }
}

.footer-transparent {
  background-color: transparent;

  .footer-top {
    .footer-top-links-wrapper {
      ul {
        li {
          padding: 0.5rem 3rem;

          &:not(:last-of-type) {
            border-right: 1px solid $gray-color;
          }
        }
      }
    }

    .footer-top-first-row {
      .footer-top-left {
        p {
          font-size: 14px;
        }
      }
    }
  }
}

.immuno-oncology-banner-section {
  max-height: 384px;
  width: 100%;
  height: calc(100vw / 5);
  display: flex;
  flex-direction: column;
  justify-content: center;

  .container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    max-height: 100%;

    h1 {
      font-size: 55px;
      max-width: 620px;
      font-weight: 300;
      margin: 0;
    }

    img {
      max-width: 198px;
      height: calc(284px + 2rem);
      max-height: 100%;
      padding: 1rem 0;
    }
  }
}

.need-help-exploring-section {
  background-color: rgba(98, 178, 198, 0.1);

  .container {
    padding: 2rem 0;

    .need-help-exploring-content-wrapper {
      background-color: #ffffff;
      border-top: 10px solid #EEE7E7;
      display: grid;
      grid-template-columns: repeat(2, minmax(0, 1fr));
      grid-gap: 1rem;

      .need-help-exploring-content-wrapper-inner {
        padding: 2.5rem 0 0;

        h2 {
          font-size: 22px;
          font-weight: 600;
          margin: 1.5rem 0;
          line-height: 1.25;
        }

        p {
          font-size: 16px;
          max-width: 500px;
          margin: 0 0 1.5rem;
        }

        .button-orange {
          width: 209px;
          height: 32px;
        }
      }
    }
  }
}

.what-is-immuno-oncology-section {
  .container {
    h2 {
      margin: 2rem 0;
      font-size: 32px;
      font-weight: 300;
    }

    .io-items-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 4rem 0 0;

      .io-item {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;
        padding: 0 0 1.5rem;

        img {
          position: absolute;
          max-width: 265px;
          max-height: 265px;
          z-index: 1000;
        }

        .img-left {
          left: 0;
        }

        .img-right {
          right: 0;
        }

        .io-item-content-inner {
          max-width: 360px;
          padding: 1.5rem 0 3rem;

          h3 {
            font-size: 16px;
            font-weight: 600;
          }

          p {
            font-size: 16px;
            margin: 0;
          }
        }

        .io-item-line {
          border-top: 2px solid #62B2C6;
          border-bottom: 2px solid #62B2C6;
          border-radius: 4px;
          width: 50%;
          position: absolute;
          bottom: 1.5rem;
          background-color: #62B2C6;
        }

        .io-item-line-left {
          left: 16%;
        }

        .io-item-line-right {
          right: 16%;
        }
      }
    }
  }
}

.order-or-download-section {
  padding: 5rem 0;

  .container:not(.section-border-top) {
    display: flex;
    flex-direction: column;
    align-items: center;

    p {
      margin: 1.5rem 0;
      font-size: 16px;
      line-height: 1.25;
    }

    h3 {
      font-size: 22px;
      font-weight: 600;
      margin: 0 0 1.5rem;
    }

    .button-orange {
      width: 132px;
      height: 32px;
    }
  }
}

.playcanvasfull {
  iframe {
    display: block;
    width: 100%;
    border: none;
    overflow-y: auto;
    overflow-x: hidden;
    height: 100%;
  }
}

.playcanvaswrap {
  height: 100vh;
}

.mobile-menu-wrapper {
  .mobile-menu-separator-fullwidth {
    box-shadow: 0 1px 1px 0 #f2e7e7;
    margin-left: -15px;
    margin-right: -15px;
    height: 1px;
  }

  .nav-link:after {
    color: $pink-color;
  }

  .menu-section-name {
    font-size: 24px;
    font-weight: 300;
    border-bottom: 2px solid #eee7e7;
    padding: 0.5rem 0;
    margin-bottom: 0.75rem;
  }

  .mobile-nav-link {
    padding: 1rem 0;
    font-size: 14px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: $gray-color;

    img {
      margin-right: 0.5rem;
      filter: invert(48%) sepia(72%) saturate(6981%) hue-rotate(283deg) brightness(81%) contrast(96%);
      max-height: 1rem;
    }
  }

  .colored-section {
    background-color: rgb(252, 236, 209);
    padding: 2rem 1rem;

    a, p {
      margin-bottom: 0;
      display: flex;
      justify-content: space-between;
      color: $gray-color;
      font-size: 18px;

      img {
        filter: invert(80%) sepia(33%) saturate(27%) hue-rotate(314deg) brightness(96%) contrast(98%);
      }
    }
  }

  .follow-us-section {
    margin: 2rem 0 1rem;

    p {
      margin-bottom: 0.5rem;
      font-size: 16px;
    }

    .social-icons-wrapper {
      height: auto;

      a > svg {
        width: 1.75rem;
      }
    }
  }
}

@media (max-width: $xl) {
  .carousel-our-medicines {
    .banner-io-teach-gradient {
      background-size: inherit;
    }
  }

  .oncology-our-research-immuno-oncology {
    .immuno-oncology-row {
      .quick-links-wrapper {
        span {
          font-size: 20px;
        }

        a {
          font-size: 16px;
        }
      }
    }
  }

  .banner-health-equity-wrapper {
    .quote {
      max-width: 520px;
      margin-bottom: 2rem;
      font-size: 24px;
    }

    .author {
      max-width: 500px;
      font-size: 14px;
    }
  }

  .opdivo-yervoy-banner-wrapper {
    .container {
      .opdivo-banner-p1 {
        font-size: 76px;
        margin-top: 1rem;
      }

      .opdivo-banner-p2 {
        font-size: 37px;
      }

      .button-orange {
        width: 304px;
        height: 38px;
        font-size: 18px;
        margin-top: 2rem;
      }
    }
  }

  .carousel-homepage {
    .carousel-slide {
      .home-slide-1-transforming {
        font-size: 2rem;
        max-width: 320px;
        position: inherit;
      }
    }
  }
}

@media (max-width: $lg) {
  .banner-health-equity-wrapper {
    .quote {
      max-width: 410px;
      margin-bottom: 1rem;
      font-size: 20px;
    }

    .author {
      max-width: 410px;
      font-size: 12px;
    }
  }

  .opdivo-yervoy-banner-wrapper {
    .container {
      .opdivo-banner-p1 {
        font-size: 60px;
        margin-top: 1rem;
      }

      .opdivo-banner-p2 {
        font-size: 29px;
      }

      .button-orange {
        width: 240px;
        height: 36px;
        font-size: 14px;
        margin-top: 1rem;
      }
    }
  }

  .carousel-homepage {
    .carousel-slide {
      .home-slide-1-welcome {
        font-size: 1rem;
      }

      .home-slide-1-latest {
        font-size: 24px;
        font-weight: 300;
        max-width: 460px;
        margin: 0 0 1rem;
      }

      .home-slide-1-h2 {
        margin: 0.5rem 0 1rem;
        font-size: 50px;
      }

      .button-pink {
        height: 40px;
        width: 180px;
        font-size: 16px;
      }

      .home-slide-1-transforming {
        font-size: 1.5rem;
        max-width: 240px;
        position: inherit;
        margin: 1rem 0 0;
      }
    }
  }
}

@media (max-width: $md) {
  .container {
    padding-left: 25px;
    padding-right: 25px;
  }

  .coming-soon-footer {
    .footer-top {
      padding: 2rem 0;
      max-width: 330px;
      margin: 0 auto;

      .logo {
        width: auto;
        max-width: 100%;
      }
    }
  }

  .coming-soon-header {
    .navbar-logo-wrapper {
      max-width: 100%;
      padding: 0;
      margin: 0 auto;

      .logo {
        width: auto;
        max-width: 100%;
      }
    }
  }

  .carousel-homepage {
    .carousel-slide {
      height: calc(100vw / 1.51);
      position: relative;

      h2 {
        font-size: 26px;
        line-height: 30px;
      }

      .container {
        height: 100%;

        .row {
          height: 100%;
        }

        .button-pink {
          width: 157px;
          height: 32px;
          font-size: 14px;
        }

        .home-slide-1-wrapper {
          display: flex;
          flex-direction: column;
          justify-content: center;
        }

        .home-slide-1-welcome {
          font-size: 26px;
          max-width: 200px;
        }

        .home-slide-2-wrapper {
          display: flex;
          flex-direction: column;
          justify-content: center;

          h2 {
            max-width: 190px;
          }

          .button-pink {
            width: 114px;
            height: 32px;
            font-size: 14px;
          }
        }

        .home-slide-3-wrapper {
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          text-align: left;
          justify-content: center;
          height: 100%;

          h2 {
            max-width: 170px;
            margin: 0 0 2rem;
          }

          .button-pink {
            font-size: 14px;
            width: 114px;
            height: 30px;
            margin-right: 54px;
          }
        }

        .home-slide-4-wrapper {
          display: flex;
          flex-direction: column;
          justify-content: center;
          padding-left: 1.75rem;

          h2 {
            font-size: 26px;
            max-width: 210px;
            margin: 0 0 2rem;
          }

          .button-pink {
            width: 118px;
            height: 30px;
            font-size: 14px;
          }
        }

        .home-slide-5-wrapper {
          display: flex;
          flex-direction: column;
          justify-content: center;

          h2 {
            max-width: 120px;
            margin: 0 0 2rem;
          }

          .button-pink {
            width: 134px;
            height: 32px;
            font-size: 14px;
          }
        }
      }

      .home-slide-2-not-patient {
        right: auto;
        left: 7%;
        font-size: 7px;
        bottom: 0.75rem;
      }

      .home-slide-3-not-patient {
        left: 69%;
        font-size: 7px;
        bottom: 0.75rem;
      }

      .home-slide-4-not-patient {
        color: #fff;
        left: 9%;
        font-size: 7px;
        bottom: 0.75rem;
      }

      .home-slide-5-not-patient {
        font-size: 7px;
        bottom: 0.75rem;
        left: 2rem;
      }
    }
  }

  .carousel-indicators {
    bottom: 0.75rem;

    li {
      margin: 0 0.25rem;
      width: 16px;
      height: 4px;
    }
  }

  .homepage-cards-wrapper {
    padding: 3rem 0;

    .container {
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: 2rem;

      .homepage-card {
        padding: 1.25rem 2.5rem 1.25rem;

        h3 {
          margin: 0;
        }

        > img {
          max-height: 86px;
          max-width: 100px;
          margin: 3rem auto;
        }

        .arrow-link-pink {
          font-size: 20px;
        }

        &:not(:last-of-type) {
          margin: 0;
        }
      }
    }
  }

  .homepage-cards-middle-wrapper {
    padding: 0px 0px;

    .container{
      padding-left:0;
      padding-right:0;
      padding-top:0;
      .homepage-middle-card {
        flex-direction: column;
        .homepage-middle-card-single {
          .homepage-middle-card-line {
            margin: 1.5rem 0 3.25rem 0;
          }
        }
      }

    }

    .homepage-cards-middle-top {
      padding: 2rem;
      flex-direction: column;
      margin-bottom: 1rem;

      .card-left {
        h3 {
          font-size: 26px;
        }

        p {
          font-size: 20px;
          margin-bottom: 0.75rem;
        }
      }

      .card-right {
        padding-left: 0;
        border-left: none;
        margin-left: 0;
        padding-top: 0;

        .card-right-border-top {
          border-top: 2px solid #707070;
          width: 148px;
          margin-bottom: 2rem;
        }

        .date {
          font-size: 26px;
        }

        .time {
          font-size: 26px;
          margin-bottom: 1rem;
        }

        .button-pink {
          max-width: 208px;
          height: 30px;
          font-size: 14px;
        }
      }
    }

    .homepage-cards-middle-bottom {
      grid-template-columns: 1fr;
      grid-gap: 1rem;
      padding: 0.5rem 0;

      .homepage-middle-card {
        h3 {
          font-size: 20px;
          margin-bottom: 0.75rem;
        }

        .homepage-middle-card-line {
          display: none;
        }

        .arrow-link-pink {
          font-size: 16px;
        }

        .arrow-link-pink:not(:last-child) {
          margin-bottom: 0.5rem;
        }
      }
    }
  }

  .homepage-cards-bottom-wrapper {
    padding: 25px 0;
    background-color: #fff;

    .container {
      grid-template-columns: 1fr;
      grid-gap: 1rem;

      .homepage-bottom-card {
        padding: 2.5rem;
        align-items: center;

        h3 {
          font-size: 24px;
        }

        .button-pink {
          width: 122px;
          height: 30px;
          font-size: 14px;
        }
      }
    }
  }

  .handholding-section {
    max-height: 100%;
    height: calc(100vw / 1.68);
    background-position: initial;

    h2 {
      font-size: 28px;
      max-width: 200px;
    }

    .trade-icon {
      top: -8px;
      font-size: 20px;
      font-weight: 100;
    }
  }

  footer {
    .footer-top {
      padding: 3rem 0 1rem;

      .container {
        display: flex;
        flex-direction: column-reverse;

        .footer-top-links-wrapper {
          ul {
            flex-direction: column;
            margin: 0;

            li {
              font-size: 12px;
              padding: 0;
              margin-bottom: 0.75rem;

              &:not(:last-of-type) {
                border: none;
              }
            }
          }
        }

        .footer-top-first-row {
          flex-direction: column-reverse;

          .footer-top-right {
            float: none;

            p {
              font-size: 12px;
              margin: 1rem 0 0.5rem;
            }
          }

          .footer-top-left {
            .logo {
              width: 155px;
              height: 40px;
              margin: 2rem 0 1rem;
            }

            p {
              font-size: 12px;

              &:last-of-type {
                margin-top: 1rem
              }
            }
          }
        }
      }
    }
  }

  .modal-homepage-wrapper {
    .modal-dialog {
      margin: 0 25px;

      .modal-body {
        padding: 3rem 4rem 6rem;

        ul.homepage-modal-links {
          li {
            a {
              line-height: 1.25;
            }

            &:not(:last-of-type) {
              margin-bottom: 2rem;
            }
          }
        }
      }
    }
  }

  .banner-theater-schedule-wrapper {
    max-height: 100%;
    height: calc(100vw / 1.33);

    h1 {
      font-size: 28px;
      line-height: 1.25;
      max-width: 160px;
      margin: 0;
    }
  }



  .smiling-guy-wrapper {
    height: calc(100vw / 1.18);
    justify-content: flex-start;
    align-items: center;

    .container {
      align-items: center;

      .content-wrapper {
        max-width: 100%;
        padding: 1.75rem 0 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;

        h2 {
          font-size: 18px;
          margin-bottom: 1.25rem;
        }

        .button-pink {
          width: 138px;
          height: 35px;
          font-size: 16px;
        }
      }

      .medicines-smiling-guy-content-wrapper {
        max-width: none;
        padding: 0;
        margin-top: 2rem;

        p {
          font-size: 18px;
          line-height: 22px;
        }
      }
    }
  }

  .banner-health-equity-wrapper {
    justify-content: flex-start;
    height: calc(100vw * 1.12);

    .quote {
      font-size: 20px;
      max-width: 210px;
      margin: 1.5rem 0;
    }
  }

  .health-equity-main-section {
    padding: 2.5rem 0 3.5rem;

    .health-equity-top {
      padding: 2rem 1rem;

      h2 {
        font-size: 23px;
      }

      .join-us {
        font-size: 17px;
        max-width: 295px;
        margin-bottom: 1.5rem;
      }

      .watch-live {
        font-size: 20px;
        margin-bottom: 1.5rem;
      }

      .partisipant-biographies {
        font-size: 17px;
        margin-bottom: 7rem;
      }

      .partisipant-cards-wrapper {
        grid-template-columns: 1fr;
        grid-gap: 8rem;

        .partisipant-card {
          padding: 7rem 2rem 2rem;

          p {
            font-size: 16px;

            &:last-of-type {
              margin-bottom: 0;
            }
          }
        }
      }

      .watch-live-state-2, .watch-live-state-3, .watch-live-state-4 {
        margin: 2rem 0;
      }
    }

    .health-equity-bottom {
      margin-top: 3rem;

      .survivorship-today-wrapper {
        flex-direction: column;
        padding: 3rem 0;

        .survivorship-today-left {
          .button-pink {
            font-size: 16px;
          }
        }

        .survivorship-today-right {
          margin: 4rem 0 0;
          border-left: none;
          padding: 0;

          .survivorship-today-right-border {
            border-top: 4px solid #707070;
            width: 202px;
            margin: 0 auto;
            padding-bottom: 3rem;
          }
        }
      }

      .survivorship-cards {
        grid-template-columns: 1fr;

        .survivorship-card-single {
          .text-content-wrapper {
            .watch-link-wrapper {
              margin-top: 4.5rem;
              margin-bottom: 0.5rem;

              span {
                font-size: 20px;
              }
            }
          }
        }
      }
    }
  }

  .marker-lady-wrapper {
    height: calc(100vw / 1.33);
    background-repeat: no-repeat;

    h1 {
      font-size: 32px;
      line-height: 1.25;
      margin: 0;
      max-width: 150px;
    }
  }

  .abstracts-cards-section {
    margin: 2rem 0 4rem;

    h3 {
      padding-top: 0.5rem;
      margin-bottom: 1rem;
    }

    .abstract-cards-wrapper {
      grid-template-columns: 1fr;
      grid-gap: 0.75rem;
    }

    .accordion {
      .card {
        border-radius: 0;
        border: none;
        box-shadow: 0 0 6px 0 #f2e7e7;

        .card-header {
          background-color: transparent;
          padding: 0.625rem 0;

          button {
            color: $pink-color;
            width: 100%;
            text-align: left;
            font-size: 18px;
            text-decoration: none;
            box-shadow: none;
            position: relative;
            line-height: 1;
            background: transparent;
            border: none;

            &::after {
              content: "+";
              position: absolute;
              right: 0.75rem;
              top: -0.5rem;
              font-size: 3rem;
            }

            &:active, &:focus {
              box-shadow: none;
            }

            &.active {
              &::after {
                content: "\2013";
              }
            }
          }
        }

        .card-body {
          padding: 0;

          .abstract-card {
            .description {
              font-size: 18px;
              line-height: 28px;
            }
          }
        }

        &:not(:last-of-type) {
          margin-bottom: 0.75rem;
        }
      }
    }
  }

  .carousel-our-medicines {
    .carousel-slide {
      height: calc(100vw / 1.33);
      justify-content: flex-start;

      .our-medicines-slide-1-h2 {
        font-size: 32px;
        margin: 3rem 0 0;
      }

      .our-medicines-slide-2-content-wrapper {
        flex-direction: column;
        align-items: center;

        .our-medicines-slide-2-p1 {
          font-size: 52px;
          line-height: 1;
          margin-top: 1.5rem;
        }

        .our-medicines-slide-2-p2 {
          font-size: 25px;
          line-height: 1;
        }

        .button-orange {
          width: 117px;
          height: 32px;
          font-size: 12px;
          margin-top: 7.75rem;
        }
      }

      .our-medicines-slide-3-wrapper {
        p {
          font-size: 30px;
          line-height: 34px;
          max-width: 230px;
          margin: 2rem 0 2.5rem;
        }

        .button-orange {
          width: 125px;
          height: 32px;
          font-size: 14px;
        }
      }

      .our-medicines-slide-4-wrapper {
        h2 {
          font-size: 32px;
          line-height: 1.25;
          max-width: 170px;
          margin: 2.5rem 0 3.25rem;
        }

        .button-pink {
          width: 109px;
          height: 32px;
          font-size: 12px;
        }
      }

      .our-medicines-slide-4-not-patient {
        font-size: 13px;
        left: auto;
        right: 6%;
        bottom: 1.75rem;
      }
    }
  }

  .section-medicines-cards {
    margin: 2rem 0;

    .container {
      grid-template-columns: 1fr;

      .card-fullwidth {
        grid-column: auto;

        .medicines-inner {
          a {
            padding: 0.75rem 0 1.75rem;
          }
        }
      }

      .medicines-card {
        h3 {
          font-size: 18px;
        }

        .medicines-inner {
          min-height: 140px;
          padding: 1rem;
        }
      }
    }
  }

  .please-see-paragraph {
    font-size: 20px;
  }

  .our-medicines-bottom-wrapper {
    margin: 3rem 25px 3rem;

    .container {
      padding: 1.5rem 2rem;

      h3 {
        font-size: 25px;
      }

      p {
        font-size: 15px;
        margin-bottom: 1.5rem;
      }

      .button-pink {
        margin-bottom: 0.5rem;
      }
    }
  }

  .isi-popup-content {
    max-height: calc(100vh - 65px);
    top: 65px;
    z-index: 1100;

    .container {
      margin: 1rem 0 0;

      .isi-popup-toggle-btn {
        right: 2rem;
        top: 0.25rem;
      }

      h3 {
        line-height: 20px;
      }

      .isi-popup-top-wrapper {
        flex-direction: column-reverse;

        .important-safety-information {
          padding: 0;
          max-width: 100%;
        }
      }
    }

    &.isi-popup-toggled {
      top: 70vh;
      max-height: 30vh;
    }
  }

  .footer-transparent {
    .footer-top {
      padding: 1rem 0 4rem;

      .container {
        flex-direction: column;

        .footer-top-links-wrapper {
          ul {
            li {
              a {
                font-size: 12px;
              }
            }
          }
        }

        .footer-top-first-row {
          .footer-top-left {
            .logo {
              margin: 1rem 0;
            }
          }

          .footer-top-right {
            p {
              margin-top: 2rem;
            }
          }
        }
      }
    }
  }

  .opdivo-yervoy-banner-wrapper {
    height: calc(100vw / 1.302);

    .container {
      flex-direction: row;
      position: relative;

      .opdivo-banner-p1 {
        font-size: 22px;
        margin: 1.25rem 0.5rem 0 0;
      }

      .opdivo-banner-p2 {
        font-size: 22px;
        margin: 1.25rem 0 0 0;
      }

      .button-orange {
        position: absolute;
        bottom: 1.25rem;
        width: 283px;
        height: 33px;
        font-size: 16px;
        margin-top: 0;

        img {
          height: 18px;
          margin-left: 0.5rem;
        }
      }
    }
  }

  .opdivo-top {
    margin-top: 25px;

    .product-information-wrapper {
      .product-information-wrapper-inner {
        h2 {
          font-size: 18px;
        }

        ul {
          li {
            font-size: 12px;
          }
        }
      }
    }

    .indications {
      padding: 0 15px;

      h2 {
        margin: 2rem 0 1rem;
      }

      .indication-box {
        flex-direction: column;
        height: 175px;

        img {
          margin: 0;
          max-height: 76px;
        }

        p {
          font-size: 1rem;
          line-height: 16px;
          text-align: center;
          margin: 0.25rem 0 0.5rem;
        }

        .learn-more {
          font-size: 10px;
          margin-top: auto;
          position: initial;
        }
      }
    }
  }

  .opdivo-explore-section {
    margin-top: 3rem;
    padding: 0 25px;

    .container {
      padding: 0;
      border: none;

      h2 {
        font-size: 20px;
        margin: 1.5rem 0;
      }

      .explore-boxes-wrapper {
        grid-template-columns: 1fr;
        grid-gap: 1rem;

        .explore-box {
          .explore-box-cover-photo {
            padding-bottom: 52%;
            background-size: contain;
          }

          .explore-box-info {
            padding: 1.75rem 1.75rem 1.25rem;

            h3 {
              font-size: 20px;
            }

            .link-wrapper {
              margin: 2.5rem 1rem 0 0;

              a {
                font-size: 18px;
              }
            }
          }
        }
      }
    }
  }

  .opdivo-product-resources-section {
    margin-bottom: 2rem;

    h2 {
      font-size: 20px;
      margin: 3rem 0 1.5rem;

      &.opdivo-folfox-h2, &.opdivo-cabometyx-h2 {
        margin: 3rem 0 1.5rem;
      }
    }

    .product-resource-boxes-wrapper {
      grid-template-columns: 1fr;
      grid-gap: 25px;
      margin: 0;

      .product-resource-box {
        .product-resource-box-cover-photo {
          padding-bottom: 52%;
          background-position: center top;

          .product-resource-box-type {
            span {
              margin-top: 2px;
            }
          }
        }

        .product-resource-box-info {
          padding: 1.75rem 2rem 0.75rem;

          h3 {
            font-size: 18px;
            line-height: 20px;
          }

          .link-wrapper {
            a {
              font-size: 18px;
            }
          }
        }
      }
    }
  }

  .opdivo-banner-section-bottom {
    padding-bottom: 30vh;
    .container {
      height: calc(100vw * 1.123);
      flex-direction: column;
      padding: 2.5rem 0 3.5rem;

      .col-md-6:first-of-type {
        text-align: center;
      }

      .paragraph-wrapper {
        margin-top: 2rem;
        max-width: 260px;

        p {
          font-size: 18px;
          line-height: 27px;
        }
      }
    }
  }

  .modal-indications-popup {
    .modal-dialog {
      max-height: calc(70vh - 8rem);
      max-width: calc(100vw - 80px);
      margin: 5rem auto 3rem;
      display: flex;

      .modal-body {
        padding: 2rem;
        max-height: 100%;
        padding: 2rem 2.75rem;

        .proceed-to-site {
          width: 236px;
          height: 36px;
          background: #F26322;
          color: #fff;
          position: absolute;
          top: -27px;
          font-size: 22px;
          text-transform: uppercase;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 0.375rem;
          left: 50%;
          transform: translate(-50%, 0);
          padding-top: 2px;
        }

        .popup-content-wrapper {
          overflow: auto;
          max-height: 280px;

          h2 {
            color: #003B5C;
            font-size: 16px;
            font-weight: 600;
            line-height: 18px;
            text-transform: uppercase;
            margin-bottom: 1rem
          }

          p {
            font-size: 16px;
            line-height: 20px;
          }
        }
      }
    }
  }

  .modal-open {
    overflow: hidden !important;
  }

  .opdivo-cabometyx-banner-wrapper {
    height: calc(100vw / 1.302);

    .container {
      flex-direction: row;
      position: relative;
      justify-content: center;
      align-items: flex-end;
      display: flex;

      .button-blue {
        position: absolute;
        bottom: 2.75rem;
        width: 290px;
        height: 54px;
        font-size: 16px;
        margin-top: 0;
        display: block;
        text-align: center;
        line-height: 18px;
        padding-top: 0.75rem;

        img {
          height: 18px;
          margin-left: 0.5rem;
        }
      }
    }
  }

  .opdivo-folfox-banner-wrapper {
    height: calc(100vw / 1.302);

    .container {
      flex-direction: row;
      position: relative;
      justify-content: center;
      align-items: flex-end;
      display: flex;

      .button-orange {
        position: absolute;
        bottom: 2.75rem;
        width: 290px;
        height: 54px;
        font-size: 16px;
        margin-top: 0;
        display: block;
        text-align: center;
        line-height: 18px;
        padding-top: 0.75rem;
        left: auto;

        img {
          height: 18px;
          margin-left: 0.5rem;
        }
      }
    }
  }

  .banner-oncology-our-research {
    max-height: unset;
    background-position: center top;
    background-size: contain;
    background-repeat: no-repeat;
    width: 100%;
    height: auto;

    .container {
      padding-top: calc((100vw / 2.48) + 1.5rem);

      > img {
        max-width: 50%;
        margin-bottom: 1rem;
      }

      h1 {
        font-size: 28px;
        line-height: 32px;
        margin-bottom: 2rem;
      }

      .banner-line-pink {
        border-top: 2px solid #BE2BBB;
        border-bottom: 2px solid #BE2BBB;
        width: 114px;
        border-radius: 2px;
        margin-bottom: 2rem;
      }

      .banner-oncology-p1 {
        font-size: 1rem;
        line-height: 1.25;
        padding-right: 1rem;
        margin-bottom: 1rem;
      }

      .banner-oncology-bottom-cells {
        align-items: flex-start;
        justify-content: space-between;

        .oncology-cell {
          flex-direction: column;
          margin-right: 0;
          max-width: 100%;
          align-items: flex-start;

          span {
            font-size: 12px;
            line-height: 1rem;
            max-width: 90px;
            margin-top: 0.25rem;
          }
        }
      }
    }
  }

  .oncology-our-research-immuno-oncology {
    margin: 3rem 0 1rem;

    .immuno-oncology-row {
      flex-direction: column;
      align-items: center;
      margin-bottom: 2rem;

      .quick-links-wrapper {
        margin-left: 0;
        flex-direction: column;
        height: auto;
        margin-top: 0;
        border-top: 1px solid #BE2BBB;
        border-bottom: 0;
        text-align: center;
        padding-top: 2rem;

        span {
          font-size: 16px;
          font-weight: 600;
        }

        a {
          font-size: 1rem;
          line-height: 16px;
          margin: 1.5rem 0 0;
          height: auto;
          justify-content: center;
          font-weight: 600;
          width: auto;
          white-space: nowrap;
          overflow: hidden;
        }
      }
    }

    .main-content {
      flex-direction: column-reverse;

      .content-left {
        .immuno-oncology-cards {
          display: grid;
          grid-template-columns: repeat(3, minmax(0, 1fr));
          grid-row-gap: 3rem;
          margin-bottom: 3rem;

          .oncology-card {
            img {
              max-height: 42px;
              max-width: unset;
              margin-bottom: 1rem;
            }

            span {
              font-size: 11px;
              line-height: 1;
            }
          }
        }

        h2 {
          font-size: 23px;
          line-height: 25px;
          font-weight: 400;
          margin-bottom: 1rem;
        }

        p {
          font-size: 16px;
          line-height: 19px;
          font-weight: 400;
          margin-bottom: 2.5rem;
        }
      }

      .immuno-oncology-links {
        a {
          font-size: 16px;
          font-weight: 600;
        }
      }

      .img-right {
        margin: 0 0 1rem;
        width: 100%;
        height: auto;
      }
    }
  }

  .potential-of-io-combinations {
    .border-top {
      margin-bottom: 2rem;
    }

    .content-wrapper {
      flex-direction: column;

      .part-left {
        .part-left-top {
          flex-direction: column-reverse;

          .img-wrapper {
            width: 100%;
            height: unset;
            padding-bottom: 84.4%;
            margin-bottom: 2.5rem;
          }

          h2 {
            margin: 0 0 1rem;
            font-size: 23px;
            line-height: 25px;
            font-weight: 400;
            padding-right: 2rem;
          }
        }
        .part-left-bottom {
          p {
            font-size: 16px;
            line-height: 19px;
          }

          a {
            font-size: 14px;
            line-height: 1;
            margin: 2rem 0 2.5rem;
            font-weight: 600;
          }
        }
      }

      .part-right {
        margin-left: 0;
        padding: 2rem 0 0;
        border-left: none;
        border-top: 2px solid #000;
        max-width: 100%;
        min-width: unset;

        p {
          font-size: 17px;
          line-height: 20px;
        }

        .part-right-middle-cells {
          .cell {
            justify-content: center;
            margin-top: 1rem;

            .cell-img-wrapper {
              margin-right: 1rem;
              background-position: right center;
              width: 70px;
              height: 70px;
            }

            .cell-right {
              span {
                font-size: 16px;
                line-height: 19px;
                margin-bottom: 0;
              }

              a {
                font-size: 1rem;
                line-height: 18px;
                font-weight: 600;
                width: auto;
                max-width: 100%;
                white-space: nowrap;
                overflow: hidden;
              }
            }
          }
        }
      }
    }

    .content-bottom {
      margin: 2rem 0 1em;

      small {
        max-width: 100%;
        margin-right: 0;
        font-size: 11px;
        display: block;
      }
    }
  }

  .searching-ways-to-restore {
    .border-top {
      margin-bottom: 3rem;
    }

    .content-wrapper {
      margin-bottom: 3rem;
      flex-direction: column-reverse;

      .img-wrapper {
        width: 100%;
        height: auto;
        padding-bottom: 84.2%;
        margin-bottom: 1rem;
      }

      .content-left {
        h2 {
          font-weight: 400;
          font-size: 23px;
          line-height: 27px;
        }

        p {
          font-size: 16px;
        }

        ul {
          padding-left: 1.5rem;

          li {
            font-size: 16px;
          }
        }

        a {
          font-size: 14px;
          font-weight: 600;
        }
      }
    }
  }

  .our-research-resources-section {
    margin-bottom: 1rem;

    .border-top {
      margin-bottom: 1.5rem;
    }

    h2 {
      margin: 0 0 2rem;
      padding: 0 25px;
    }

    .related-resources-wrapper {
      padding: 0 25px;
      grid-template-columns: 1fr;
      grid-gap: 1rem;

      .related-resource {
        .text-content-wrapper {
          padding: 1.25rem 0;

          h4 {
            font-size: 16px;
            line-height: 1.25;
            font-weight: 600;
            margin-bottom: 1.5rem;
          }

          a {
            font-size: 1rem;
            margin-right: 1.5rem;
            font-weight: 600;

            img {
              margin: 5px 0 0 0.5rem;
            }

            &:not(:first-of-type) {
              margin-top: 1rem;
            }
          }
        }
      }
    }
  }

  .now-enrolling-section {
    margin: 0 25px;

    .container {
      padding: 2rem 1.5rem;

      h2 {
        font-size: 17px;
        line-height: 1;
        margin-bottom: 1rem;
      }

      p {
        font-size: 15px;
        line-height: 1.25;
        text-align: center;
      }

      ul {
        display: block;
        text-align: center;

        li {
          display: inline-block;

          a {
            padding: 0 0.5rem;
            font-size: 15px;
            line-height: 1.25;
          }

          &:nth-child(3) {
            border-right: none;
          }
        }
      }
    }
  }

  .references-section {
    margin: 0;

    p {
      margin: 1.5rem 0;
      font-size: 12px;
    }
  }

  .hematology-medicines-modal-wrapper {
    .modal-dialog {
      max-width: 90vw;
      width: max-content;
      min-width: 26vw;
      margin: 0 auto;

      .modal-content {
        .modal-header {
          padding: 3vw 3vw 0;
        }

        .modal-body {
          padding: 3vw;

          .general-section-wrapper {
            .button-pink {
              font-size: 12px;
              min-width: 120px;
              width: auto;
              height: 32px;
              padding: 0.5rem 2.5rem;
              margin: 1rem 0 0.25rem;
            }
          }

          .detailed-section-wrapper {
            padding: 1vw 2vw;

            .button-pink {
              font-size: 12px;
              width: 120px;
              height: 32px;
              max-width: 100%;
              padding: 0.5rem 2.5rem;
            }

            .gray-download-icon-button {
              font-size: 12px;
              min-height: 32px;
              min-width: 100%;
              line-height: 1.25;
              padding: 0.5rem 2.5rem;
              height: auto;
              margin-bottom: 0.5rem;
            }

            .p-please-see {
              max-width: 100%;
              margin-bottom: 2rem;
              font-size: 12px;
            }

            p.click-a-button-below {
              margin: 2rem 0 1rem 0;
              font-size: 12px;
            }
          }
        }
      }
    }
  }

  .hematology-educational-resources-modal-wrapper {
    .modal-dialog {
      max-width: calc(100vw - 2rem);
      margin: 0 auto;

      .modal-content {
        .modal-header {
          padding: 1rem;
        }

        .modal-body {
          padding: 1rem 2rem 2rem;

          h2.hematology-section-name {
            font-size: 1.75rem;
          }

          ul.hematology-modal-links {
            max-width: 100%;
            width: 100%;
            margin: 1rem 0 0;

            li {
              a {
                font-size: 1rem;
                height: 36px;
                width: 100%;
                padding: 0.25rem 3rem;
              }
            }
          }
        }
      }
    }
  }

  .hematology-our-research-modal-wrapper {
    .modal-dialog {
      max-width: calc(100vw - 2rem);
      margin: 0 auto;

      .modal-content {
        .modal-header {
          padding: 1rem;
        }

        .modal-body {
          padding: 1rem 2rem 2rem;

          .general-section-wrapper {
            max-width: 100%;

            h2.hematology-section-name {
              font-size: 2rem;
              max-width: 100%;
            }

            ul.hematology-modal-links {
              max-width: 100%;
              width: 100%;
              margin: 1rem 0 0;

              li {
                a {
                  font-size: 1rem;
                  height: 36px;
                  width: 100%;
                  padding: 0.25rem 3rem;
                  min-width: unset;
                }
              }
            }

            .vimeo-wrapper {
              max-width: 60vw;
            }
          }
        }
      }
    }
  }

  .health-equity-modal-wrapper {
    .modal-dialog {
      max-width: calc(100vw - 2rem);
      margin: 0 auto;

      .modal-content {
        .modal-header {
          padding: 1rem;
        }

        .modal-body {
          padding: 1rem 2rem 2rem;
        }
      }
    }
  }

  .immuno-oncology-banner-section {
    height: auto;
    max-height: unset;

    .container {
      h1 {
        font-size: 26px;
        margin: 1rem 0;
      }

      img {
        max-width: 80px;
        max-height: 100%;
        padding: 1rem 0;
        margin-left: 1rem;
        height: auto;
      }
    }
  }

  .need-help-exploring-section {
    .container {
      padding: 25px;

      .need-help-exploring-content-wrapper {
        grid-template-columns: 1fr;

        img {
          max-width: 100%;
        }

        .need-help-exploring-content-wrapper-inner {
          padding: 1rem;

          h2 {
            font-size: 18px;
            margin: 1rem 0;
          }

          p {
            font-size: 14px;
            margin: 0 0 1rem;
          }

          .button-orange {
            width: 100%;
            margin-bottom: 0.5rem;
          }
        }
      }
    }
  }

  .what-is-immuno-oncology-section {
    .container {
      h2 {
        margin: 1rem 0;
        font-size: 24px;
      }

      .io-items-wrapper {
        padding: 1rem 0 0;

        .io-item {
          padding: 0 0 1rem;

          img {
            position: inherit;
            max-width: 150px;
            max-height: 150px;
          }

          .io-item-content-inner {
            max-width: 100%;
            padding: 1rem 0 2rem;

            h3, p {
              font-size: 1rem;
            }
          }

          .io-item-line-left {
            left: 20%;
            width: 60%;
          }

          .io-item-line-right {
            right: 20%;
            width: 60%;
          }
        }
      }
    }
  }

  .order-or-download-section {
    padding: 0 0 1.5rem;

    .container:not(.section-border-top) {
      p {
        margin: 1.5rem 0;
        font-size: 14px;
      }

      h3 {
        font-size: 18px;
        margin: 0 0 1.5rem;
      }
    }
  }

  .mobile-section-name-wrapper {
    background-color: #ede7e7;
    padding: 3rem 0 2rem;

    p {
      margin: 0;
      font-size: 16px;
    }

    h2 {
      font-size: 36px;
    }
  }

  .mobile-medicines-names-wrapper {
    padding: 2rem 0;

    .medicine-name {
      border-top: 3px solid #EEE7E7;
      padding: 1.5rem 0;
      display: flex;
      justify-content: space-between;
      cursor: pointer;
      font-size: 18px;

      span {
        line-height: 1;
      }

      svg {
        color: $pink-color;
      }

      &.medicine-name:last-of-type {
        border-bottom: 3px solid #EEE7E7;
      }
    }
  }

  .mobile-link-section-wrapper {
    margin-bottom: 1rem;

    .mobile-link-section-wrapper-inner {
      padding: 1.9rem;
      font-size: 18px;
      line-height: 1;
      display: flex;
      justify-content: space-between;
      color: $gray-color;

      img {
        filter: invert(83%) sepia(33%) saturate(0) hue-rotate(347deg) brightness(99%) contrast(99%);
      }
    }
  }

  .mobile-hematology-footer {
    background-color: #ede7e7;
    padding: 2rem 0;

    .mobile-hematology-footer-top {
      margin-bottom: 3rem;

      p {
        font-size: 13px;
      }
    }

    .mobile-hematology-footer-bottom {
      a {
        color: $gray-color;
        font-size: 16px;
        margin-bottom: 1rem;
        display: block;
      }

      p {
        font-size: 13px;
        margin: 0;
      }
    }
  }

  .mobile-educational-resources-names-wrapper {
    padding: 2rem 0;

    h3 {
      font-size: 30px;
      margin: 1rem 0 2.5rem;
    }

    .medicines-accordion {
      .card {
        border-left: none;
        border-right: none;
        border-top: 3px solid #EEE7E7;
        border-bottom: none;

        .card-header {
          background-color: #fff;
          padding: 2rem 0;
          display: flex;
          justify-content: space-between;
          cursor: pointer;
          font-size: 18px;
          line-height: 1;

          svg {
            color: $pink-color;
          }
        }

        &.card:last-of-type {
          border-bottom: 3px solid #EEE7E7;
        }

        .card-body {
          padding: 2rem 0 2rem 1.5rem;

          .accordion-links {
            padding: 0;
            margin: 0;
            list-style-type: none;

            li {
              a {
                color: $gray-color;
                font-size: 18px;
                line-height: 20px;
                display: flex;
                justify-content: space-between;

                img {
                  filter: invert(48%) sepia(72%) saturate(6981%) hue-rotate(283deg) brightness(81%) contrast(96%);
                }
              }

              &:not(:last-of-type) {
                margin-bottom: 1.5rem;
              }
            }
          }
        }
      }
    }
  }

  .mobile-medicine-image {
    width: 100%;
  }

  .mobile-medicine-description {
    width: 100%;
    background-color: #EEE7E7;
    padding: 2rem;

    p {
      margin: 0;
      font-size: 16px;
      line-height: 22px;
      color: $gray-color;
      text-align: center;
    }
  }

  .mobile-medicine-assets-wrapper {
    padding: 2rem 0;

    .medicine-name {
      border-top: 3px solid #EEE7E7;
      padding: 1.5rem 0;
      display: flex;
      justify-content: space-between;
      cursor: pointer;
      font-size: 18px;
      color: $gray-color;

      span {
        line-height: 1;
      }

      img {
        filter: invert(48%) sepia(72%) saturate(6981%) hue-rotate(283deg) brightness(81%) contrast(96%);
      }

      &.medicine-name:last-of-type {
        border-bottom: 3px solid #EEE7E7;
      }
    }
  }

  .mobile-medicine-back-to-home {
    padding-bottom: 2rem;

    .button-pink {
      display: flex;
      justify-content: space-around;
      align-items: center;
      width: 204px;
    }
  }
}

/*coming soon page styles*/
.main{
    position:absolute;
    top:0;
    left:0;
    background-size: cover;
    // background-image: url("background.png");
    margin:0;
    padding:0;
    width:100%;
    height:100%;
}

.logo {
    position:relative;
    margin: auto;
  }

.coming-soon {
    position:relative;
    margin: auto;
  }

.images {
    margin:auto;
    position: absolute;
    top: 50%;
    left:50%;
    transform: translate(-50%,-50%);
    display:block;
    text-align: center;
}
